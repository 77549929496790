import React, { useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { FavoritesContext } from './contexts/FavoritesContext';

function NotificationSnackbar() {
  const {
    snackbarOpen,
    snackbarMessage,
    handleSnackbarClose,
  } = useContext(FavoritesContext);

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity="info"
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export default NotificationSnackbar;