import React, { useContext } from "react";
import RecipeAddToListDialog from "./Recipes/RecipeAddToListDialog";
import { FavoritesContext } from "./contexts/FavoritesContext";

function CommonDialogs( ) {
  const { openDialog, selectedRecipeId, handleCloseDialog } =
    useContext(FavoritesContext);

  return (
    <>
      <RecipeAddToListDialog
        open={openDialog}
        onClose={handleCloseDialog}
        selectedRecipeId={selectedRecipeId}
      />
      {/* In the future, you can add more dialogs here */}
    </>
  );
}

export default CommonDialogs;
