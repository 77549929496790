import React, { useState, useEffect, useRef, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import {
  CheckCircle,
  Error as ErrorIcon,
  HourglassEmpty,
  Refresh,
  Warning as WarningIcon, // Import the Warning icon
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import io from "socket.io-client";
import UserContext from "../contexts/userContext";
import supabase from "../supabaseClient"; // Import the Supabase client
import { useTheme } from "@mui/material/styles";

function TabPanel(props) {
  const { children, value, tab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tab}
      id={`simple-tabpanel-${tab}`}
      aria-labelledby={`simple-tab-${tab}`}
      {...other}
    >
      {value === tab && (
        <Box sx={{ padding: ".75em 0em 0em 0em" }}>{children}</Box>
      )}
    </div>
  );
}

const UserUploadRecipe = () => {
  const { user, session } = useContext(UserContext);
  const accessToken = session?.access_token;
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); // Access the current URL

  // State for managing the selected tab
  const [tabName, setTabName] = useState("active"); // Default tab is "active"
  // Update tabIndex based on query parameter in URL

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab && ["active", "completed", "failed"].includes(tab)) {
      setTabName(tab);
    }
  }, [location.search]);

  // State for the recipe URL input
  const [urlInput, setUrlInput] = useState("");
  const [urlError, setUrlError] = useState(""); // To display URL validation errors

  // State for managing tasks
  const [tasks, setTasks] = useState([]);

  // Server URL
  const SERVER_URL = process.env.REACT_APP_MIGRATION_SERVER_URL;

  // Socket reference
  const socketRef = useRef(null);

  // Active tasks stored in ref
  const activeTasksRef = useRef([]);

  const handleTabChange = (event, newValue) => {
    setTabName(newValue);
    navigate(`?tab=${newValue}`, { replace: false }); // Update URL with tab name
  };

  const handleRecipeClick = (recipeId) => {
    navigate(`/recipes/edit/${recipeId}`);
  };

  useEffect(() => {
    // Initialize socket connection with authentication
    socketRef.current = io(SERVER_URL, {
      auth: {
        token: accessToken,
      },
    });

    socketRef.current.on("connect", () => {
      console.log(`Connected with socket ID: ${socketRef.current.id}`);

      // On reconnection, re-subscribe to active tasks
      activeTasksRef.current.forEach((task) => {
        const taskId = task.id;
        socketRef.current.emit("subscribeToTask", { taskId });
      });
    });

    // Handle socket disconnection
    socketRef.current.on("disconnect", (reason) => {
      console.log(`Socket disconnected: ${reason}`);
    });

    // Handle socket connection errors
    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    return () => {
      // Cleanup on unmount
      socketRef.current.disconnect();
    };
  }, [SERVER_URL, accessToken]);

  useEffect(() => {
    // On component mount, retrieve tasks from Supabase
    const fetchTasksFromSupabase = async () => {
      try {
        if (!user) {
          console.error("User is not authenticated");
          return;
        }

        // Fetch tasks belonging to the user with relevant statuses
        const { data: fetchedTasks, error } = await supabase
          .from("tasks")
          .select("*")
          .eq("user_id", user.id)
          .eq("task_type", "process_recipe")
          .in("status", [
            "pending",
            "queued",
            "in_progress",
            "completed",
            "completed_with_warning",
            "failed",
          ])
          .order("updated_at", { ascending: false }); // Fetch in order of most recent updated

        if (error) {
          console.error("Error fetching tasks from Supabase:", error.message);
          return;
        }

        if (fetchedTasks && fetchedTasks.length > 0) {
          // Update tasks state and subscribe to websocket events
          fetchedTasks.forEach((task) => {
            // Determine isLoading based on task status
            const isLoading = ["pending", "in_progress", "queued"].includes(
              task.status
            );

            // Map task status to display status
            let displayStatus = task.status.toLowerCase();
            // Do not change 'completed_with_warning' to 'completed'
            if (
              displayStatus === "pending" ||
              displayStatus === "in_progress"
            ) {
              displayStatus = "reconnecting...";
            }

            // Add task to tasks state
            setTasks((prevTasks) => {
              const taskExists = prevTasks.some((t) => t.id === task.id);
              if (!taskExists) {
                const newTask = {
                  id: task.id,
                  url: task.metadata.url, // Assuming the URL is stored in metadata
                  status: displayStatus,
                  isLoading,
                  error: null,
                  result: task.result || null, // Include the result field
                  logs: task.logs || null, // Include the logs field
                  updatedAt: task.updated_at, // Include updated_at for sorting
                };
                return [...prevTasks, newTask];
              } else {
                return prevTasks;
              }
            });

            // If task is pending or in progress, subscribe to websocket events
            if (isLoading) {
              activeTasksRef.current.push({
                id: task.id,
                url: task.metadata.url,
              });
              socketRef.current.emit("subscribeToTask", { taskId: task.id });
              listenForTaskUpdates(task.id);
            }
          });
        }
      } catch (err) {
        console.error("Error fetching tasks from Supabase:", err);
      }
    };

    fetchTasksFromSupabase();
  }, [user]);

  // Function to handle adding a URL
  const handleAddUrl = async () => {
    if (urlInput.trim() !== "") {
      // Validate the URL and strip UTM parameters
      try {
        const cleanedUrl = cleanUrl(urlInput.trim());

        // Check if an active task already exists for this URL
        const existingActiveTask = tasks.find(
          (task) => task.url === cleanedUrl && isTaskActive(task.status)
        );
        if (existingActiveTask) {
          setUrlError("Recipe is already being processed.");
          return;
        }

        setUrlInput("");
        setUrlError("");

        // Start processing the recipe URL
        await processRecipeUrl(cleanedUrl);
      } catch (err) {
        console.error("Invalid URL:", err);
        setUrlError("Please enter a valid URL.");
      }
    }
  };

  // Function to clean and validate the URL
  const cleanUrl = (inputUrl) => {
    try {
      const urlObj = new URL(inputUrl);

      // Remove UTM parameters
      urlObj.searchParams.forEach((value, key) => {
        if (key.toLowerCase().startsWith("utm_")) {
          urlObj.searchParams.delete(key);
        }
      });

      return urlObj.toString();
    } catch (err) {
      throw new Error("Invalid URL");
    }
  };

  // Function to process a recipe URL
  const processRecipeUrl = async (url) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/process-recipe-url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
        body: JSON.stringify({ url }),
      });

      const result = await response.json();

      if (response.ok && result.task_id) {
        const taskId = result.task_id;

        // Check if the task already exists
        const existingTask = tasks.find((task) => task.id === taskId);
        if (!existingTask) {
          const newTask = {
            id: taskId,
            url,
            status: "queued",
            isLoading: true,
            error: null,
            result: null,
            logs: null,
            updatedAt: new Date().toISOString(), // Set updatedAt to now
          };

          setTasks((prevTasks) => [...prevTasks, newTask]);

          // Store the task in active tasks
          activeTasksRef.current.push({ id: taskId, url });

          // Subscribe to task updates
          socketRef.current.emit("subscribeToTask", { taskId });

          // Listen for task updates
          listenForTaskUpdates(taskId);
        }
      } else {
        console.error(`Error starting task: ${JSON.stringify(result)}`);
        alert(`Error starting task: ${result.error || "Unknown error"}`);
      }
    } catch (err) {
      console.error("Error processing recipe URL:", err);
      alert("An error occurred while processing the recipe URL.");
    }
  };

  // Function to listen for task updates
  const listenForTaskUpdates = (taskId) => {
    const updateListener = (data) => {
      if (data.taskId === taskId) {
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === taskId
              ? {
                  ...task,
                  status: data.message?.toLowerCase() || "processing",
                  updatedAt: new Date().toISOString(), // Update the timestamp
                }
              : task
          )
        );
      }
    };

    const completeListener = (data) => {
      if (data.taskId === taskId) {
        console.log(`Task ${taskId} completed with status: ${data.status}`);
        setTasks((prevTasks) => {
          const updatedTasks = prevTasks.map((task) =>
            task.id === taskId
              ? {
                  ...task,
                  status: data.result.status || 'completed',
                  isLoading: false,
                  result: data.result,
                  logs: data.logs || task.logs,
                  updatedAt: new Date().toISOString(),
                }
              : task
          );
          return updatedTasks;
        });
    
        // Remove task from active tasks
        activeTasksRef.current = activeTasksRef.current.filter(
          (task) => task.id !== taskId
        );
    
        removeTaskListeners();
      }
    };

    const failedListener = (data) => {
      if (data.taskId === taskId) {
        setTasks((prevTasks) => {
          // Update the task
          const updatedTasks = prevTasks.map((task) =>
            task.id === taskId
              ? {
                  ...task,
                  status: "failed",
                  isLoading: false,
                  error: data.error,
                  logs: data.logs || task.logs, // Update logs if available
                  updatedAt: new Date().toISOString(),
                }
              : task
          );

          return updatedTasks;
        });

        // Remove task from active tasks
        activeTasksRef.current = activeTasksRef.current.filter(
          (task) => task.id !== taskId
        );

        removeTaskListeners();
      }
    };

    // Attach listeners
    socketRef.current.on(`task-update-${taskId}`, updateListener);
    socketRef.current.on(`task-complete-${taskId}`, completeListener);
    socketRef.current.on(`task-failed-${taskId}`, failedListener);

    // Function to remove listeners
    const removeTaskListeners = () => {
      socketRef.current.off(`task-update-${taskId}`, updateListener);
      socketRef.current.off(`task-complete-${taskId}`, completeListener);
      socketRef.current.off(`task-failed-${taskId}`, failedListener);
    };
  };

  // Function to handle reprocessing a task
  const handleReprocess = (task) => {
    processRecipeUrl(task.url);
  };

  // Helper function to check if a task is active
  const isTaskActive = (status) => {
    return !["completed", "completed_with_warning", "failed"].includes(status);
  };

  // Helper function to extract a display title from the URL
  const getDisplayTitle = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname.replace("www.", "");
    } catch {
      return "recipe";
    }
  };

  // Function to sort tasks by updatedAt
  const sortTasksByUpdatedAt = (taskArray) => {
    return [...taskArray].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
  };

  // Categorize and sort tasks
  // Active tasks
  const activeTasks = sortTasksByUpdatedAt(
    tasks.filter((task) => isTaskActive(task.status))
  );
  // Completed tasks (exclude 'completed_with_warning')
  const completedTasks = sortTasksByUpdatedAt(
    tasks.filter((task) => task.status === "completed")
  );
  // Failed tasks (include 'completed_with_warning')
  const failedTasks = sortTasksByUpdatedAt(
    tasks.filter((task) =>
      ["failed", "completed_with_warning"].includes(task.status)
    )
  );

  // TaskItem component to render each task
  const TaskItem = ({ task }) => (
    <Paper
      key={task.id}
      elevation={0}
      sx={{
        mb: 2,
        p: 2,
        position: "relative",
        border: `1px solid ${theme.palette.border.main}`,
        wordWrap: "break-word",
        width: "100%",
      }}
    >
      <ListItem sx={{ alignItems: "flex-start", padding: 0, width: "100%" }}>
        <ListItemIcon sx={{ mt: ".5em", minWidth: "40px" }}>
          {task.status === "completed" && task.result?.recipes?.length > 0 && (
            <CheckCircle color="success" />
          )}
          {(task.status === "completed_with_warning" ||
            task.result?.recipes?.length === 0) && (
            <WarningIcon color="warning" />
          )}
          {task.status === "failed" && <ErrorIcon color="error" />}
          {isTaskActive(task.status) && <HourglassEmpty color="action" />}
        </ListItemIcon>
        <ListItemText
          primary={getDisplayTitle(task.url)}
          secondary={task.url}
        />
        {/* Reprocess Button */}
        {["completed", "completed_with_warning", "failed"].includes(
          task.status
        ) && (
          <IconButton
            onClick={() => handleReprocess(task)}
            sx={{ position: "absolute", top: -5, right: -5 }}
          >
            <Refresh />
          </IconButton>
        )}
      </ListItem>

      {isTaskActive(task.status) && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          status: {task.status}
        </Typography>
      )}

      {/* Show progress indicator */}
      {task.isLoading && isTaskActive(task.status) && (
        <LinearProgress sx={{ mt: 1 }} />
      )}
      {/* Error message */}
      {task.status === "failed" && task.error && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {task.error}
        </Typography>
      )}
      {/* Error Logs */}
      {task.status === "failed" && task.logs && (
        <Box sx={{ mt: 1 }}>
          {task.logs
            .filter((log) => log.level === "error")
            .map((log, index) => (
              <Typography key={index} variant="body2" color="error">
                [{new Date(log.timestamp).toLocaleTimeString()}] {log.message}
              </Typography>
            ))}
        </Box>
      )}
      {/* Display recipes (same logic as before) */}
      {["completed", "completed_with_warning"].includes(task.status) && (
        <>
          {task.result &&
          Array.isArray(task.result.recipes) &&
          task.result.recipes.length > 0 ? (
            // Display recipe cards if recipes are found
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                {task.result.recipes.length === 1 ? "recipe" : "recipes"}
              </Typography>
              <Grid container spacing={2}>
                {task.result.recipes.map((recipe) => (
                  <Grid item xs={12} key={recipe.recipe_id}>
                    <Card
                      elevation={0}
                      sx={{
                        border: `1px solid ${theme.palette.border.main}`,
                      }}
                    >
                      <CardActionArea
                        onClick={() => handleRecipeClick(recipe.recipe_id)}
                        sx={{ display: "flex", alignItems: "flex-start" }}
                      >
                        {recipe.image_url && (
                          <CardMedia
                            component="img"
                            sx={{
                              width: 60,
                              height: 60,
                              objectFit: "cover",
                              margin: 1,
                              borderRadius: theme.shape.borderRadius,
                              filter:
                                theme.palette.mode === "dark"
                                  ? "brightness(0.8)"
                                  : "none",
                            }}
                            image={recipe.image_url}
                            alt={recipe.title}
                          />
                        )}
                        <CardContent
                          sx={{
                            flex: "1",
                            padding: 1,
                            alignSelf: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            sx={{
                              wordWrap: "break-word",
                              textTransform: "lowercase",
                            }}
                          >
                            {recipe.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            // Display "No recipes found" message if no recipes are available
            <Typography variant="body2" color="warning" sx={{ mt: 1 }}>
              no recipes found.
            </Typography>
          )}
        </>
      )}
      {/* Created At Timestamp */}
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ mt: 2, fontSize: "0.75rem", justifySelf: "right" }}
      >
        {new Date(task.updatedAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </Typography>
    </Paper>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {/* Upload Recipe via URL */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            upload recipe via url
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="urlInput"
            label="recipe url"
            name="urlInput"
            value={urlInput}
            onChange={(e) => {
              setUrlInput(e.target.value);
              setUrlError("");
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddUrl();
              }
            }}
            error={Boolean(urlError)}
            helperText={urlError}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUrl}
            sx={{ mt: 2 }}
            disabled={!urlInput.trim()}
          >
            add url
          </Button>
        </Box>
      </Grid>

      {/* Recipe Processing Queue */}
      <Grid item xs={12} md={6}>
        <Box sx={{ mt: 1, width: "100%" }}>
          {tasks.length === 0 ? (
            ""
          ) : (
            <>
              <Tabs value={tabName} onChange={handleTabChange}>
                <Tab label={`Active (${activeTasks.length})`} value="active" />
                <Tab
                  label={`Completed (${completedTasks.length})`}
                  value="completed"
                />
                <Tab label={`Failed (${failedTasks.length})`} value="failed" />
              </Tabs>
              <TabPanel value={tabName} tab="active">
                {" "}
                {activeTasks.length === 0 ? (
                  ""
                ) : (
                  <List sx={{ width: "100%" }}>
                    {activeTasks.map((task) => (
                      <TaskItem key={task.id} task={task} />
                    ))}
                  </List>
                )}
              </TabPanel>
              <TabPanel value={tabName} tab="completed">
                {" "}
                {completedTasks.length === 0 ? (
                  <Typography variant="body1">no completed recipes.</Typography>
                ) : (
                  <List sx={{ width: "100%" }}>
                    {completedTasks.map((task) => (
                      <TaskItem key={task.id} task={task} />
                    ))}
                  </List>
                )}
              </TabPanel>
              <TabPanel value={tabName} tab="failed">
                {" "}
                {failedTasks.length === 0 ? (
                  <Typography variant="body1">no failed recipes.</Typography>
                ) : (
                  <List sx={{ width: "100%" }}>
                    {failedTasks.map((task) => (
                      <TaskItem key={task.id} task={task} />
                    ))}
                  </List>
                )}
              </TabPanel>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserUploadRecipe;
