import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography, useTheme } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import GroceryIcon from "./assets/grocery.svg";
import SprintIcon from "./assets/sprint.svg";
import AlarmWaitIcon from "./assets/alarm_wait.svg";
import SwipeIcon from "@mui/icons-material/Swipe"; // Import SwipeIcon
import "./RecipeDetail.css";
import QuickActions from "./RecipeQuickActions";
import { FavoritesContext } from "../contexts/FavoritesContext";

const ANIMATED_RECIPES_COUNT = 10;
const DESIRED_SPEED = 200; // em/s

function RecipeDetail({
  recipe,
  index,
  onRecipeClick,
  disableExpansion,
  expanded,
  isSelected,
  isHinting,
  onHintComplete,
}) {
  const { favoritedRecipes } = useContext(FavoritesContext);
  const isFavorited = favoritedRecipes.includes(recipe.id);
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(expanded || false);
  const [contentHeight, setContentHeight] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState("0.3s");
  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 100,
  // });
  const [swipeIconAnimating, setSwipeIconAnimating] = useState(false);
  const [cardMovingOver, setCardMovingOver] = useState(false);
  const [showHintOverlay, setShowHintOverlay] = useState(false);
  const [, /*isMobile*/ setIsMobile] = useState(window.innerWidth <= 768);

  const [showAllIngredients, setShowAllIngredients] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const theme = useTheme();

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Hint sequence effect
  useEffect(() => {
    let cardMoveTimeout;
    let showActionsTimeout;
    let resetHintTimeout;
    let overlayTimeout;

    if (isHinting) {
      const SWIPE_ICON_DURATION = 1000; // Match CSS animation duration
      const CARD_MOVE_DURATION = 250;
      const ACTIONS_DISPLAY_DURATION = 2000;
      const TOTAL_HINT_DURATION =
        SWIPE_ICON_DURATION + CARD_MOVE_DURATION + ACTIONS_DISPLAY_DURATION;

      // Start the swipe icon animation and show the overlay
      // setSwipeIconVisible(true);
      setShowHintOverlay(true);
      setSwipeIconAnimating(true);

      // Move the card over after the swipe icon animation ends
      cardMoveTimeout = setTimeout(() => {
        setCardMovingOver(true);
      }, SWIPE_ICON_DURATION);

      // Hide the overlay after the swipe icon animation ends
      overlayTimeout = setTimeout(() => {
        setShowHintOverlay(false);
      }, SWIPE_ICON_DURATION);

      // Show the quick actions after the card has moved
      showActionsTimeout = setTimeout(() => {
        setShowActions(true);
      }, SWIPE_ICON_DURATION + CARD_MOVE_DURATION);

      // Reset the hint after displaying actions
      resetHintTimeout = setTimeout(() => {
        setSwipeIconAnimating(false);
        // Keep the swipe icon visible at the end
        setCardMovingOver(false);
        setShowActions(false);

        // Optionally, hide the swipe icon after additional duration
        // If you want to hide the icon after some time, uncomment below:
        // setTimeout(() => {
        //   setSwipeIconVisible(false);
        // }, 2000); // Adjust the delay as needed

        // Inform parent component that hint sequence is complete
        if (onHintComplete) {
          onHintComplete();
        }
      }, TOTAL_HINT_DURATION);

      return () => {
        clearTimeout(cardMoveTimeout);
        clearTimeout(showActionsTimeout);
        clearTimeout(resetHintTimeout);
        clearTimeout(overlayTimeout);
      };
    }
  }, [isHinting, onHintComplete]);

  const updateContentHeight = useCallback(() => {
    if (contentRef.current) {
      const heightInPx = contentRef.current.scrollHeight;
      setContentHeight(heightInPx);
      const heightInEm = heightInPx / 16;
      const durationInSeconds = heightInEm / DESIRED_SPEED;
      setTransitionDuration(`${durationInSeconds}s`);
    }
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        updateContentHeight();
      });
      resizeObserver.observe(contentRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [contentRef, updateContentHeight]);

  useLayoutEffect(() => {
    updateContentHeight();
  }, [recipe, isOpen, showAllIngredients, updateContentHeight]);

  useEffect(() => {
    window.addEventListener("resize", updateContentHeight);
    return () => {
      window.removeEventListener("resize", updateContentHeight);
    };
  }, [updateContentHeight]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowActions(true);

      // Cancel the hint sequence
      setSwipeIconAnimating(false);
      setCardMovingOver(false);
    },
    onSwipedRight: () => {
      setShowActions(false);

      // Cancel the hint sequence
      setSwipeIconAnimating(false);
      setCardMovingOver(false);
    },
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: false,
    delta: 10,
  });

  const handleTap = (e) => {
    if (e.target.closest(".quick-action-button")) {
      return;
    }
    if (!showActions) {
      if (disableExpansion) {
        if (onRecipeClick) {
          onRecipeClick(recipe);
        }
      } else {
        setIsOpen(!isOpen);
      }
    } else {
      setShowActions(false);
    }

    // Cancel the hint sequence
    setSwipeIconAnimating(false);
    setCardMovingOver(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "ingredient",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px",
          }}
        >
          <div>{params.row.name}</div>
          {params.row.preparation && (
            <div
              style={{
                fontStyle: "italic",
                color: "gray",
                marginTop: "0.25em",
              }}
            >
              {params.row.preparation}
            </div>
          )}
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "quantity",
      flex: 0.5,
      minWidth: 145, // Ensures the header "Quantity" is not cut off
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "8px",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  // Helper function to sanitize values
  function sanitize(value) {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "string" && value.toLowerCase() === "null") {
      return "";
    }
    return value;
  }

  const rows = recipe.ingredients.map((ingredient, idx) => ({
    id: idx,
    name: sanitize(ingredient.name).toLowerCase(),
    preparation: sanitize(ingredient.preparation).toLowerCase(),
    quantity: `${sanitize(ingredient.quantity?.value)} ${sanitize(
      ingredient.quantity?.unit
    )}`.trim(),
  }));

  const hasMoreThanSixIngredients = rows.length > 6;

  // Populate content with animation
  useEffect(() => {
    const baseDelay = 200;
    const staggerDelay = 150;

    const timer = setTimeout(
      () => {
        setIsVisible(true);
      },
      index < ANIMATED_RECIPES_COUNT
        ? baseDelay + staggerDelay * index
        : baseDelay + staggerDelay * ANIMATED_RECIPES_COUNT
    );

    return () => clearTimeout(timer);
  }, [index]);

  const MAX_LINES = 5; // Set the number of lines you want

  // Helper function to get line height
  function getLineHeight(element) {
    const computedStyle = window.getComputedStyle(element);
    let lineHeight = computedStyle.lineHeight;

    if (lineHeight === "normal") {
      const fontSize = parseFloat(computedStyle.fontSize);
      lineHeight = fontSize * 1.2; // Adjust the multiplier if needed
    } else {
      lineHeight = parseFloat(lineHeight);
    }

    return lineHeight;
  }

  // Update useEffect hook
  useEffect(() => {
    if (descriptionRef.current) {
      const descriptionElement = descriptionRef.current;
      const lineHeight = getLineHeight(descriptionElement);
      const maxHeight = lineHeight * MAX_LINES;

      if (descriptionElement.scrollHeight > maxHeight) {
        setIsDescriptionTruncated(true);
      } else {
        setIsDescriptionTruncated(false);
      }
    }
  }, [recipe.description, showFullDescription, isOpen, MAX_LINES]);

  const handleViewOriginalRecipe = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // If the recipe source is already a full URL, use it directly
    if (recipe.path.startsWith("http")) {
      window.open(recipe.path, "_blank");
    } else {
      // Otherwise, construct the relative URL as before
      const encodedBookName = encodeURIComponent(recipe.books.name);

      const pathParts = recipe.path.split("/");
      const limitedPath = pathParts.slice(-2).join("/");
      const encodedPath = encodeURIComponent(limitedPath);

      const encodedRecipeTitle = encodeURIComponent(recipe.title);

      const finalURL = `/books/${encodedBookName}?spineLocation=${encodedPath}&query=${encodedRecipeTitle}`;

      window.open(finalURL, "_blank", "noopener,noreferrer");
    }
  };

  function formatTime(minutes) {
    if (minutes <= 59) {
      return `${minutes}m`;
    } else {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      if (mins === 0) {
        return `${hours}h`;
      } else {
        return `${hours}h${mins}m`;
      }
    }
  }

  return (
    <div
      key={recipe.id}
      {...handlers}
      style={{
        border: `1px ${
          isSelected
            ? `solid ${theme.palette.border.highlight}`
            : `solid ${theme.palette.divider}`
        }`,
        borderRadius: ".5em",
        marginBottom: "1em",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        touchAction: "pan-y",
        backgroundColor: theme.palette.background.paper,
      }}
      onClick={handleTap}
    >
      {/* Content Wrapper */}
      <div
        className="recipe-content"
        style={{
          display: "flex",
          flexDirection: "column",
          transform:
            showActions || cardMovingOver
              ? "translateX(-208px)"
              : "translateX(0)",
          transition: "transform 0.3s ease",
          backgroundColor: isSelected
            ? theme.palette.background.highlight
            : theme.palette.background.paper,
        }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            padding: ".75em .75em 0 .75em",
          }}
        >
          {/* Left Column */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              style={{
                margin: 0,
                fontSize: 17,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 400,
                lineHeight: "1.4",
                wordWrap: "break-word",
                color: theme.palette.text.primary,
                ...(isOpen
                  ? {}
                  : {
                      display: "-webkit-box",
                      WebkitLineClamp: MAX_LINES,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }),
              }}
            >
              {recipe.title.toLowerCase()}
            </Typography>

            <Typography
              variant="body2"
              style={{
                marginTop: "2px",
                color: theme.palette.text.secondary,
                fontSize: 12,
                fontFamily: theme.typography.fontFamily,
                fontStyle: "italic",
                fontWeight: 400,
                lineHeight: "20px",
                ...(isOpen
                  ? {}
                  : {
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }),
              }}
            >
              <em>{recipe.books.name}</em>
            </Typography>

            {/* Stats bar */}
            <div
              style={{
                width: "100%",
                paddingTop: 4,
                paddingBottom: ".75em",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Stats Wrapper */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  fontFamily: theme.typography.fontFamily,
                  marginTop: "5px",
                }}
              >
                {/* First stat: Ingredients */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 18, height: 18, flexShrink: 0 }}>
                    <img
                      src={GroceryIcon}
                      alt="Ingredients Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {recipe.ingredients.length}
                  </div>
                </div>

                {/* Second stat: Active Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 20, height: 20, flexShrink: 0 }}>
                    <img
                      src={SprintIcon}
                      alt="Active Time Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {formatTime(recipe.time)}
                  </div>
                </div>

                {/* Third stat: Inactive Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon */}
                  <div style={{ width: 20, height: 20, flexShrink: 0 }}>
                    <img
                      src={AlarmWaitIcon}
                      alt="Inactive Time Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* Text */}
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                      flexShrink: 0,
                    }}
                  >
                    {formatTime(recipe.inactive_time)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div style={{ display: "flex", paddingTop: "0px" }}>
            {!isOpen && recipe.image_url && (
              <Box
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: ".5em",
                  border: `1px solid ${theme.palette.divider}`,
                  overflow: "hidden",
                  flexShrink: 0,
                  marginLeft: "1em",
                }}
              >
                <img
                  alt={`${recipe.title}`}
                  src={recipe.image_url}
                  onLoad={updateContentHeight}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    transform: "scale(1.1)",
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.8)"
                        : "none",
                  }}
                />
              </Box>
            )}
          </div>
        </div>

        {/* Expanded Content */}
        <div
          ref={contentRef}
          style={{
            overflow: disableExpansion ? "visible" : "hidden",
            transition: disableExpansion
              ? "none"
              : `max-height ${transitionDuration} ease`,
            maxHeight: disableExpansion
              ? "none"
              : isOpen
              ? showAllIngredients
                ? "none"
                : `${contentHeight}px`
              : "0px",
            padding: "0 0.75em",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {isOpen && (
            <>
              <Typography
                variant="body1"
                style={{
                  marginBottom: "1em",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "14px",
                  lineHeight: "20px", // Ensure this matches the lineHeight in getLineHeight
                  color: theme.palette.text.primary,
                  ...(isDescriptionTruncated && !showFullDescription
                    ? {
                        display: "-webkit-box",
                        WebkitLineClamp: MAX_LINES,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        position: "relative",
                      }
                    : {}),
                }}
                ref={descriptionRef}
              >
                {recipe.description ? recipe.description : ""}
                {isDescriptionTruncated && !showFullDescription && (
                  <span
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      background: `linear-gradient(to right, transparent, ${theme.palette.background.default} 10%)`,
                      paddingLeft: "12px",
                      fontWeight: "500",
                      cursor: "pointer",
                      color: theme.palette.primary.main,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFullDescription(true);
                    }}
                  >
                    ...Show more
                  </span>
                )}
              </Typography>
              {recipe.image_url && (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      maxWidth: "500px",
                      height: "500px",
                      borderRadius: ".5em",
                      border: `1px solid ${theme.palette.divider}`,
                      overflow: "hidden",
                      marginBottom: "1em",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      alt={`${recipe.title}`}
                      src={recipe.image_url}
                      onLoad={updateContentHeight}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        transform: "scale(1.1)",
                        filter:
                          theme.palette.mode === "dark"
                            ? "brightness(0.9)"
                            : "none",
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                  maxWidth: "900px",
                  margin: "0 auto",
                  position: "relative",
                }}
              >
                <DataGrid
                  rows={
                    showAllIngredients || !hasMoreThanSixIngredients
                      ? rows
                      : rows.slice(0, 6)
                  }
                  columns={columns}
                  disableRowSelectionOnClick
                  disableColumnMenu={true}
                  getRowHeight={() => "auto"}
                  autoHeight
                  sx={{
                    borderRadius: ".5em",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "12px",
                    border: `1px solid ${theme.palette.divider}`,
                    "& .MuiDataGrid-columnHeader": {
                      color: theme.palette.text.primary,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600",
                      fontSize: "14px",
                      padding: "10px",
                    },
                    "& .MuiDataGrid-columnHeaderDraggableContainer": {
                      width: "fit-content",
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: theme.palette.action.hover,
                    },
                    "& .MuiDataGrid-row:nth-of-type(even)": {
                      backgroundColor: theme.palette.background.paper,
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: theme.palette.action.selected,
                    },
                    "& .MuiDataGrid-cell": {
                      display: "block",
                      lineHeight: "1.5em",
                      padding: "10px",
                      boxSizing: "border-box",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      minHeight:
                        showAllIngredients || !hasMoreThanSixIngredients
                          ? 0
                          : "52px",
                      border: "none",
                    },
                    "& .MuiTablePagination-root": {
                      display: "none",
                    },
                  }}
                />
                {hasMoreThanSixIngredients && !showAllIngredients && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "55px",
                      backgroundColor: theme.palette.background.default,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      fontFamily: theme.typography.fontFamily,
                      cursor: "pointer",
                      fontWeight: "600",
                      border: `1px solid ${theme.palette.divider}`,
                      borderBottomLeftRadius: ".5em",
                      borderBottomRightRadius: ".5em",
                      zIndex: 2,
                    }}
                    onClick={() => setShowAllIngredients(true)}
                  >
                    + show all ingredients
                  </div>
                )}
              </Box>

              <Box
                style={{
                  "--primary-color": theme.palette.primary.dark,
                  "--secondary-color": theme.palette.secondary.main,
                  "--text-color": theme.palette.text.primary,
                  "--font-family": theme.typography.fontFamily,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    marginTop: "1.5em",
                    marginBottom: "1em",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "16px",
                    fontWeight: "500",
                    color: theme.palette.text.primary,
                  }}
                >
                  instructions
                </Typography>
                <ol className="customList">
                  {recipe.instructions.map((instruction, idx) => (
                    <li key={idx} className="customListItem">
                      {instruction}
                    </li>
                  ))}
                </ol>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1em 0",
                }}
              >
                <Button
                  onClick={handleViewOriginalRecipe}
                  variant="outlined"
                  sx={{
                    padding: "0.5em 1.5em",
                    borderRadius: ".25em",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: "0.85rem",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  view original recipe
                </Button>
              </Box>
            </>
          )}
        </div>
      </div>

      <QuickActions
        recipe={recipe}
        isFavorited={isFavorited}
        showActions={showActions}
      />

      {/* Overlay */}
      {showHintOverlay && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.background.overlay,
            zIndex: 1, // Ensure it's above the card content
            pointerEvents: "none", // Allow interactions to pass through
          }}
        />
      )}

      {/* Swipe Icon displayed during the hint */}
      {swipeIconAnimating && isVisible && (
        <div
          style={{
            position: "relative",
            right: "10px",
            // top: "0%",
            // transform: "translateY(-100%)",
            display: "flex",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <SwipeIcon
            className={swipeIconAnimating ? "swipe-icon-animate" : ""}
            style={{
              position: "absolute",
              bottom: "calc(100% + 12px)",
              left: "90%",
              transform: "translate(-50%, -100%)",
              color: theme.palette.primary.primary,
              // width: '24px',
              // height: '24px',
            }}
          />
        </div>
      )}
    </div>
  );
}

export default RecipeDetail;
