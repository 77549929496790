import React, { useState, useEffect, useContext } from "react";
import supabase from "../supabaseClient";
import {
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import UserContext from "../contexts/userContext";

function HouseholdSettings() {
  const { user, session } = useContext(UserContext);
  const theme = useTheme();
  const [household, setHousehold] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [householdName, setHouseholdName] = useState("");
  const [loading, setLoading] = useState(true);
  const [invitationLink, setInvitationLink] = useState("");
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [leaveConfirmDialogOpen, setLeaveConfirmDialogOpen] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [inviteTab, setInviteTab] = useState(0); // New state for tab selection
  const [copied, setCopied] = useState(false);
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // Fetch Household Members
  const fetchHouseholdMembers = async (householdId) => {
    try {
      const { data: members, error } = await supabase
        .from("households_users")
        .select(
          "user_id, role, joined_at, profiles ( first_name, last_name, email, avatar_url )"
        )
        .eq("household_id", householdId);
      if (error) throw error;
      setHouseholdMembers(members);
    } catch (error) {
      console.error("Error fetching household members:", error);
    }
  };

  useEffect(() => {
    const fetchHousehold = async () => {
      try {
        if (!user) return;
        // Check if user is part of a household
        const { data: householdUser, error } = await supabase
          .from("households_users")
          .select("household_id, role")
          .eq("user_id", user.id)
          .single();
        if (error) {
          if (error.code === "PGRST116") {
            // Not found, user is not part of a household
            setHousehold(null);
          } else {
            throw error;
          }
        } else {
          // User is part of a household
          const { data: householdData, error: householdError } = await supabase
            .from("households")
            .select("*")
            .eq("id", householdUser.household_id)
            .single();
          if (householdError) throw householdError;
          setHousehold(householdData);
          setIsOwner(householdUser.role === "owner");
          // Fetch household members
          await fetchHouseholdMembers(householdUser.household_id);
        }
      } catch (error) {
        console.error("Error fetching household:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHousehold();
  }, [user]);

  const handleCreateHousehold = async () => {
    if (!householdName) return;
    try {
      setLoading(true);
      // Check if user is already in a household
      const { data: existingHouseholdUser, error: existingError } =
        await supabase
          .from("households_users")
          .select("*")
          .eq("user_id", user.id)
          .maybeSingle();
      if (existingError) throw existingError;
      if (existingHouseholdUser) {
        // User is already in a household
        alert("You are already a member of a household.");
        return;
      }
      // Create a new household
      const { data: newHousehold, error: householdError } = await supabase
        .from("households")
        .insert({ name: householdName, owner_id: user.id })
        .select("*")
        .single();
      if (householdError) throw householdError;
      // Add user to households_users as owner
      const { error: householdsUsersError } = await supabase
        .from("households_users")
        .insert({
          household_id: newHousehold.id,
          user_id: user.id,
          role: "owner",
          joined_at: new Date(),
        });
      if (householdsUsersError) throw householdsUsersError;

      setHousehold(newHousehold);
      setIsOwner(true);
      await fetchHouseholdMembers(newHousehold.id);
    } catch (error) {
      console.error("Error creating household:", error);
      alert("Error creating household");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async () => {
    if (!isOwner) {
      alert("Only the owner can remove members");
      return;
    }
    if (!memberToRemove) return;

    if (memberToRemove.user_id === user.id) {
      alert("Owner cannot remove themselves");
      setConfirmDialogOpen(false);
      return;
    }
    try {
      const { error } = await supabase
        .from("households_users")
        .delete()
        .eq("household_id", household.id)
        .eq("user_id", memberToRemove.user_id);
      if (error) throw error;
      // Refresh members list
      setHouseholdMembers(
        householdMembers.filter(
          (member) => member.user_id !== memberToRemove.user_id
        )
      );
      setConfirmDialogOpen(false);
    } catch (error) {
      console.error("Error removing member:", error);
      alert("Error removing member");
    }
  };

  const handleInviteMember = async () => {
    try {
      const accessToken = session?.access_token;
      if (!accessToken) {
        alert("User is not authenticated");
        return;
      }
      setInvitationLink(""); // Reset invitation link
      setEmailSent(false); // Reset email sent status
      setEmailError(""); // Reset email error
      setRecipientEmail(""); // Reset recipient email
      setInviteTab(0); // Default to "Send via Email" tab
      setInviteDialogOpen(true);
      // Call the server endpoint to generate an invitation
      const response = await fetch(`${SERVER_URL}/households/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ household_id: household.id }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to create invitation");
      }
      setInvitationLink(data.invitationLink);
    } catch (error) {
      console.error("Error creating invitation:", error);
      alert("Error creating invitation");
    }
  };

  const handleSendInvitationEmail = async () => {
    try {
      const accessToken = session?.access_token;
      if (!accessToken) {
        alert("User is not authenticated");
        return;
      }
      setEmailError("");
      setEmailSent(false);
      setSendingEmail(true);

      // Call the server endpoint to send the invitation email
      const response = await fetch(
        `${SERVER_URL}/households/send-invitation-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            household_id: household.id,
            recipient_email: recipientEmail,
          }),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "failed to send invitation email");
      }
      setEmailSent(true);
    } catch (error) {
      console.error("error sending invitation email:", error);
      setEmailError(error.message || "failed to send invitation email");
    } finally {
      setSendingEmail(false);
    }
  };

  const handleLeaveHousehold = async () => {
    if (isOwner) {
      alert("Owner cannot leave the household. Consider disbanding it.");
      setLeaveConfirmDialogOpen(false);
      return;
    }
    try {
      const { error } = await supabase
        .from("households_users")
        .delete()
        .eq("household_id", household.id)
        .eq("user_id", user.id);
      if (error) throw error;
      setHousehold(null);
      setHouseholdMembers([]);
      setIsOwner(false);
      setLeaveConfirmDialogOpen(false);
    } catch (error) {
      console.error("Error leaving household:", error);
      alert("Error leaving household");
    }
  };

  const openConfirmDialog = (member) => {
    setMemberToRemove(member);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setMemberToRemove(null);
    setConfirmDialogOpen(false);
  };

  const openLeaveConfirmDialog = () => {
    setLeaveConfirmDialogOpen(true);
  };

  const closeLeaveConfirmDialog = () => {
    setLeaveConfirmDialogOpen(false);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!user) {
    return <Typography>Please log in to manage your settings.</Typography>;
  }

  return (
    <Box>
      {!household ? (
        <Box>
          <Typography variant="body" gutterBottom>
            create a household to share recipes and lists with other users.
          </Typography>
          <TextField
            label="household name"
            value={householdName}
            onChange={(e) => setHouseholdName(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
          />
          <Button
            variant="contained"
            onClick={handleCreateHousehold}
            disabled={!householdName}
            sx={{ mt: 2 }}
          >
            create household
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6">
            you are {isOwner ? "an owner" : "a member"} of household "
            {household.name}"
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            members:
          </Typography>
          <List>
            {householdMembers.map((member) => (
              <ListItem key={member.user_id}>
                <Avatar
                  src={member.profiles?.avatar_url || ""}
                  alt={`${member.profiles?.first_name || "Unknown"} ${
                    member.profiles?.last_name || "User"
                  }`}
                  sx={{ mr: 2 }}
                />
                <ListItemText
                  primary={
                    member.profiles
                      ? `${member.profiles.first_name} ${member.profiles.last_name}`
                      : "Unknown User"
                  }
                  secondary={member.role}
                />
                {isOwner && member.user_id !== user.id && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => openConfirmDialog(member)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          {isOwner ? (
            <Button
              variant="contained"
              onClick={handleInviteMember}
              sx={{ mt: 2, mr: 2 }}
            >
              invite
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={openLeaveConfirmDialog}
              sx={{ mt: 2 }}
            >
              leave household
            </Button>
          )}
          {/* Invitation Dialog */}
          <Dialog
            open={inviteDialogOpen}
            onClose={() => setInviteDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.background.modal, // Replace with your desired color
              },
            }}
          >
            <DialogTitle>invite member</DialogTitle>
            <DialogContent>
              <Tabs
                value={inviteTab}
                onChange={(e, newValue) => setInviteTab(newValue)}
                aria-label="Invitation Tabs"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab label="email" />
                {isMobileDevice && (<Tab label="sms" disabled={!isMobileDevice} />)}
                <Tab label="link" />
              </Tabs>
              {inviteTab === 0 && (
                <Box sx={{ mt: 2 }}>
                  {/* <DialogContentText>
                    enter the email address of the person you want to invite:
                  </DialogContentText> */}
                  <TextField
                    fullWidth
                    label="email address"
                    value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                  {emailError && (
                    <Typography color="error" sx={{ mt: 1 }}>
                      {emailError}
                    </Typography>
                  )}
                  {emailSent && (
                    <Typography color="primary" sx={{ mt: 1 }}>
                      invitation email sent successfully.
                    </Typography>
                  )}
                </Box>
              )}
              {inviteTab === (isMobileDevice ? 1 : -1) && (
                // Send via Text content
                <Box sx={{ mt: 2 }}>
                  {/* <DialogContentText>
                    send an invitation via text message.
                  </DialogContentText> */}
                  <TextField
                    fullWidth
                    value={invitationLink}
                    InputProps={{
                      readOnly: true,
                    }}
                    inputProps={{
                      style: {
                        fontSize: ".8rem", // Adjust the font size as desired
                      },
                    }}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const smsBody = encodeURIComponent(
                        `join my household on reciply! ${invitationLink}`
                      );
                      window.location.href = `sms:?&body=${smsBody}`;
                    }}
                    sx={{ mt: 2 }}
                  >
                    open text messenger 
                  </Button>
                </Box>
              )}

              {inviteTab === (isMobileDevice ? 2 : 1) && (
                <Box sx={{ mt: 2 }}>
                  {/* <DialogContentText>
                    copy the invitation link and send it to the person you want
                    to invite:
                  </DialogContentText> */}
                  <TextField
                    fullWidth
                    value={invitationLink}
                    InputProps={{
                      readOnly: true,
                    }}
                    inputProps={{
                      style: {
                        fontSize: ".8rem", // Adjust the font size as desired
                      },
                    }}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(invitationLink);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 5000); // Reset after 3 seconds
                    }}
                    disabled={copied}
                    sx={{ mt: 2 }}
                  >
                    {copied
                      ? "link copied to clipboard!"
                      : "copy invitation link"}
                  </Button>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setInviteDialogOpen(false)}>close</Button>
              {inviteTab === 0 && (
                <Button
                  onClick={handleSendInvitationEmail}
                  disabled={!recipientEmail || sendingEmail}
                >
                  {sendingEmail ? "sending..." : "send invite"}
                </Button>
              )}
            </DialogActions>
          </Dialog>
          {/* Confirmation Dialog for Removing Member */}
          <Dialog
            open={confirmDialogOpen}
            onClose={closeConfirmDialog}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">
              confirm remove member
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                are you sure you want to remove{" "}
                {memberToRemove?.profiles
                  ? `${memberToRemove.profiles.first_name} ${memberToRemove.profiles.last_name}`
                  : "this member"}{" "}
                from the household? they will lose access to shared resources,
                including recipes and lists. this action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmDialog}>cancel</Button>
              <Button onClick={handleRemoveMember} color="secondary">
                remove member
              </Button>
            </DialogActions>
          </Dialog>
          {/* Confirmation Dialog for Leaving Household */}
          <Dialog
            open={leaveConfirmDialogOpen}
            onClose={closeLeaveConfirmDialog}
            aria-labelledby="leave-confirm-dialog-title"
            aria-describedby="leave-confirm-dialog-description"
          >
            <DialogTitle id="leave-confirm-dialog-title">
              confirm leave household
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="leave-confirm-dialog-description">
                are you sure you want to leave your current household ("
                {household.name}")? you will lose access to shared resources,
                including recipes and lists.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeLeaveConfirmDialog}>cancel</Button>
              <Button onClick={handleLeaveHousehold} color="secondary">
                leave household
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
}

export default HouseholdSettings;
