import React from "react";
import { Skeleton, useTheme } from "@mui/material";

function RecipeLoadingSkeleton() {
  const theme = useTheme();

  return (
    <div
      style={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: ".5em",
        marginBottom: "1em",
        opacity: 1,
        transition: "opacity 0.5s ease",
        position: "relative",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {/* Content Wrapper */}
      <div
        className="recipe-content"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: ".75em .75em 0 .75em",
          }}
        >
          {/* Left Column */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* Title Placeholder */}
            <Skeleton variant="text" width="60%" height={24} />

            {/* Book Name Placeholder */}
            <Skeleton
              variant="text"
              width="40%"
              height={18}
              style={{ marginTop: 8 }}
            />

            {/* Stats Bar */}
            <div
              style={{
                width: "100%",
                paddingTop: 4,
                paddingBottom: ".75em",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Stats Wrapper */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: "5px",
                }}
              >
                {/* First Stat: Ingredients */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon Placeholder */}
                  <Skeleton variant="circular" width={18} height={18} />
                  {/* Text Placeholder */}
                  <Skeleton variant="text" width={20} height={18} />
                </div>

                {/* Second Stat: Active Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon Placeholder */}
                  <Skeleton variant="circular" width={20} height={20} />
                  {/* Text Placeholder */}
                  <Skeleton variant="text" width={30} height={18} />
                </div>

                {/* Third Stat: Inactive Time */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* Icon Placeholder */}
                  <Skeleton variant="circular" width={20} height={20} />
                  {/* Text Placeholder */}
                  <Skeleton variant="text" width={30} height={18} />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div style={{ display: "flex", paddingTop: "2px" }}>
            {/* Image Placeholder */}
            <div
              style={{
                width: "75px",
                height: "75px",
                borderRadius: ".5em",
                border: `1px solid ${theme.palette.divider}`,
                overflow: "hidden",
                flexShrink: 0,
                marginLeft: "1em",
              }}
            >
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecipeLoadingSkeleton