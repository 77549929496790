import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import AddCircle from "@mui/icons-material/AddCircle";
import supabase from "../supabaseClient";
import UserContext from "../contexts/userContext";

function RecipeAddToListDialog({ open, onClose, selectedRecipeId }) {
  const {user} = useContext(UserContext)
  const [selectedListIds, setSelectedListIds] = useState([]);
  const [newListName, setNewListName] = useState("");
  const [recipeInLists, setRecipeInLists] = useState([]);
  const [showCreateList, setShowCreateList] = useState(false);
  const [userLists, setUserLists] = useState([]);

  useEffect(() => {
    if (user) {
      const fetchUserLists = async () => {
        const { data, error } = await supabase
          .from("saved_lists")
          .select("*")
          .eq("user_id", user.id);

        if (!error) {
          setUserLists(data);
        }
      };

      fetchUserLists();
    }
  }, [user]);

  useEffect(() => {
    const fetchListsForRecipe = async () => {
      if (selectedRecipeId) {
        const { data, error } = await supabase
          .from("lists_recipes")
          .select("list_id")
          .eq("recipe_id", selectedRecipeId);

        if (!error) {
          setRecipeInLists(data.map((item) => item.list_id));
        }
      }
    };

    if (open) {
      fetchListsForRecipe();
    }
  }, [open, selectedRecipeId]);

  const handleCreateList = async () => {
    if (newListName.trim() === "") return;

    const { data, error } = await supabase
      .from("saved_lists")
      .insert([{ list_name: newListName, user_id: user.id }])
      .single()
      .select();

    if (error) {
      console.error("Error creating new list:", error);
    } else {
      setNewListName("");
      setUserLists((prev) => [...prev, data]);
    }
  };

  const handleAddToList = async (e) => {
    e.stopPropagation();

    if (selectedListIds.length === 0 || !selectedRecipeId) return;

    const insertData = selectedListIds.map((listId) => ({
      list_id: listId,
      recipe_id: selectedRecipeId,
    }));

    try {
      const { error } = await supabase.from("lists_recipes").insert(insertData);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error adding recipe to lists:", error);
    }

    // Reset states and close the dialog
    setSelectedListIds([]);
    setShowCreateList(false);
    onClose();
  };

  const handleRemoveFromList = async (e, listId) => {
    e.stopPropagation();

    try {
      const { data, error } = await supabase
        .from("lists_recipes")
        .delete()
        .eq("list_id", listId)
        .eq("recipe_id", selectedRecipeId);

      if (error) {
        throw error;
      }

      console.log("Recipe removed successfully:", data);

      // Update recipeInLists to remove the listId
      setRecipeInLists((prev) => prev.filter((id) => id !== listId));
    } catch (error) {
      console.error("Error removing recipe from list:", error);
    }
  };

  const handleClose = () => {
    setSelectedListIds([]);
    setShowCreateList(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ paddingBottom: 0 }}>Add recipe to list</DialogTitle>
      <DialogContent sx={{ paddingBottom: ".5em" }}>
        <List sx={{ paddingTop: "0!important" }} component="nav">
          {userLists
            .sort(
              (a, b) =>
                (recipeInLists.includes(a.list_id) ? 1 : -1) -
                (recipeInLists.includes(b.list_id) ? 1 : -1)
            )
            .map((list) => (
              <ListItem
                button
                key={list.list_id}
                onClick={() => {
                  if (recipeInLists.includes(list.list_id)) return;

                  if (selectedListIds.includes(list.list_id)) {
                    setSelectedListIds((prev) =>
                      prev.filter((id) => id !== list.list_id)
                    );
                  } else {
                    setSelectedListIds((prev) => [...prev, list.list_id]);
                  }
                }}
                style={{
                  background: recipeInLists.includes(list.list_id)
                    ? "#dddddd44"
                    : "",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 150px)",
                    opacity: recipeInLists.includes(list.list_id) ? 0.8 : 1,
                    pointerEvents: recipeInLists.includes(list.list_id)
                      ? "none"
                      : "auto",
                    flex: 1,
                  }}
                >
                  {list.list_name}
                </div>

                {selectedListIds.includes(list.list_id) && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}
                {recipeInLists.includes(list.list_id) && (
                  <Button
                    color="secondary"
                    size="small"
                    style={{
                      opacity: 1,
                      color: "#999",
                      marginLeft: "8px",
                      fontSize: ".8rem",
                    }}
                    onClick={(e) => handleRemoveFromList(e, list.list_id)}
                  >
                    Remove from list
                  </Button>
                )}
              </ListItem>
            ))}
        </List>
        {showCreateList ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: ".5em",
                marginTop: ".5em",
              }}
            >
              <TextField
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="New list name"
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    height: "3em",
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateList}
                sx={{
                  backgroundColor: "white",
                  boxShadow: "none",
                  border: "1px solid #ccc",
                }}
              >
                <AddIcon style={{ color: "#aaa" }} />
              </Button>
            </div>
          </>
        ) : (
          <div
            onClick={() => setShowCreateList(true)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconButton>
              <AddCircle color="primary" />
            </IconButton>
            <Typography
              variant="body1"
              color="primary"
              sx={{ padding: ".2em", cursor: "pointer" }}
            >
              Create list
            </Typography>
          </div>
        )}
      </DialogContent>

      <DialogActions sx={{ marginBottom: ".5em" }}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddToList} color="primary" variant="contained">
          Add recipe
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecipeAddToListDialog