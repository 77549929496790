// ListOverview.jsx

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  Tabs,
  Tab,
  Pagination,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ShareIcon from '@mui/icons-material/Share';
import UserContext from '../contexts/userContext';

function ListOverview({ ownedLists, sharedLists, onNewListCreated, onTogglePin, onUpdateList }) {
  const { user } = useContext(UserContext);
  const [newListName, setNewListName] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const theme = useTheme();

  // State for search term and tab value
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); // Reset pagination when changing tabs
  };

  // Determine if a search term is present
  const hasSearchTerm = searchTerm.trim() !== '';

  // Filter lists based on search term
  const filteredOwnedLists = ownedLists.filter((list) =>
    list.list_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredSharedLists = sharedLists.filter((list) =>
    list.list_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Combine lists when searching
  const combinedLists = hasSearchTerm
    ? [...filteredOwnedLists, ...filteredSharedLists]
    : tabValue === 0
    ? filteredOwnedLists
    : filteredSharedLists;

  // Pagination
  const [page, setPage] = useState(1);
  const listsPerPage = 10;
  const totalPages = Math.ceil(combinedLists.length / listsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const currentLists = combinedLists.slice(
    (page - 1) * listsPerPage,
    page * listsPerPage
  );

  // Function to create a new list
  const handleCreateList = async () => {
    if (newListName.trim() === '') return;

    const { data, error } = await supabase
      .from('saved_lists')
      .insert([
        {
          list_name: newListName,
          user_id: user.id,
        },
      ])
      .select();

    if (error) {
      console.error('Error creating new list:', error);
    } else if (data && data.length > 0) {
      setNewListName('');
      onNewListCreated(data[0]);
    }
  };

  // Function to open the share dialog
  const handleOpenShareDialog = (list) => {
    setSelectedList(list);
    setShareDialogOpen(true);
  };

  // Function to close the share dialog
  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setSelectedList(null);
  };

  // Function to toggle sharing of a list
  const handleToggleShare = async () => {
    const listId = selectedList.list_id;
    const currentSharedStatus = selectedList.shared_with_household;

    const { error } = await supabase
      .from('saved_lists')
      .update({ shared_with_household: !currentSharedStatus })
      .eq('list_id', listId)
      .eq('user_id', user.id); // Ensure only the owner can update

    if (error) {
      console.error('Error updating shared status:', error);
    } else {
      // Update the list in ownedLists using onUpdateList
      onUpdateList({
        ...selectedList,
        shared_with_household: !currentSharedStatus,
      });
      handleCloseShareDialog();
    }
  };

  return (
    <Grid>
      {/* Search Bar */}
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <TextField
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1); // Reset pagination when search term changes
          }}
          placeholder="search lists"
          variant="outlined"
          fullWidth
          sx={{
            marginBottom: theme.spacing(2),
            fontFamily: theme.typography.fontFamily,
          }}
        />
      </Box>

      {/* Tabs for list categories */}
      {!hasSearchTerm && (
        <Box sx={{ marginTop: theme.spacing(2) }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="My Lists" />
            {sharedLists.length > 0 && <Tab label="Shared with Me" />}
          </Tabs>
        </Box>
      )}

      {/* Display Lists */}
      <Box sx={{ marginTop: theme.spacing(2) }}>
        {currentLists.length > 0 ? (
          <Grid container spacing={2}>
            {currentLists.map((list) => (
              <Grid item xs={12} sm={6} md={4} key={list.list_id}>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    padding: theme.spacing(2),
                    borderRadius: '.5em',
                    cursor: 'pointer',
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: theme.shadows[2],
                    },
                    backgroundColor: theme.palette.background.paper,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Link
                      to={`/lists/${list.list_id}`}
                      style={{ textDecoration: 'none', flexGrow: 1 }}
                    >
                      <Typography
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          color: theme.palette.text.primary,
                          fontSize: '1.1rem',
                          marginBottom: theme.spacing(0.5),
                        }}
                      >
                        {list.list_name}
                        {list.shared_with_household && (
                          <Typography
                            variant="caption"
                            sx={{
                              marginLeft: theme.spacing(1),
                              color: theme.palette.text.secondary,
                            }}
                          >
                            (shared)
                          </Typography>
                        )}
                      </Typography>
                    </Link>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {list.user_id === user.id ? (
                        <>
                          {list.list_name.toLowerCase() !== 'favorites' && (
                            <>
                              <IconButton
                                color="primary"
                                onClick={() => handleOpenShareDialog(list)}
                              >
                                <ShareIcon />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={() => onTogglePin(list.list_id, list.pinned)}
                              >
                                {list.pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                              </IconButton>
                            </>
                          )}
                        </>
                      ) : (
                        // Display the owner's avatar for shared lists
                        list.profiles && (
                          <Avatar
                            src={list.profiles.avatar_url || ''}
                            alt={`${list.profiles.first_name || 'Unknown'} ${
                              list.profiles.last_name || 'User'
                            }`}
                            sx={{ width: 40, height: 40, ml: 2 }}
                          />
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No lists found.</Typography>
        )}

        {/* Pagination Component */}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'center' }}
          />
        )}
      </Box>

      {/* New list creation UI - Moved to bottom of "My Lists" */}
      {!hasSearchTerm && tabValue === 0 && (
        <Box sx={{ marginTop: theme.spacing(4) }}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          >
            <TextField
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="new list"
              variant="outlined"
              fullWidth
              sx={{
                fontFamily: theme.typography.fontFamily,
                '& .MuiOutlinedInput-root': {
                  fontFamily: theme.typography.fontFamily,
                  height: '3em',
                },
                '& .MuiOutlinedInput-input': {
                  padding: theme.spacing(2),
                },
              }}
              InputProps={{
                style: {
                  height: '3em',
                },
              }}
            />

            <Button
              variant="outlined"
              onClick={handleCreateList}
              sx={{
                minWidth: '3em',
                boxShadow: 'none',
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      )}

      {/* Share Dialog */}
      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Share List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedList &&
              (selectedList.shared_with_household
                ? `This list is currently shared with your household. Do you want to stop sharing it?`
                : `Do you want to share the list "${selectedList.list_name}" with your household?`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog}>Cancel</Button>
          <Button onClick={handleToggleShare} color="primary">
            {selectedList?.shared_with_household ? 'Stop Sharing' : 'Share'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default ListOverview;
