import React, { useState, useEffect, useRef, useContext } from "react";
   import supabase from "../supabaseClient";
   import { Autocomplete, TextField, Paper, Avatar } from "@mui/material";
   import { useTheme } from "@mui/material/styles";
   import UserContext from "../contexts/userContext";

   function DynamicFilter({ selectedFilters = [], updateFilters, filterOptions }) {
     const { user } = useContext(UserContext);
     const theme = useTheme();

     // If filterOptions is not provided, use the default list
     let defaultFilterOptions = ["Book", "Author", "Time", "Tag"];
     if (user) {
       defaultFilterOptions.push("Owner");
     }
     const filterTypes = filterOptions || defaultFilterOptions;

     const [items, setItems] = useState([]);
     const [autocompleteValue, setAutocompleteValue] = useState(null);
     const [inputValue, setInputValue] = useState("");
     const [filterType, setFilterType] = useState(filterTypes[0]);

     const inputRef = useRef(null);

     useEffect(() => {
       const fetchItems = async () => {
         if (filterType === "Time") {
           setItems([]);
         } else if (filterType === "Owner") {
           if (user) {
             setItems([user]);
           } else {
             setItems([]);
           }
         } else if (filterType === "Tag") {
           const { data, error } = await supabase
             .from("tags")
             .select("name, category:tags_categories(name)")
             .eq("is_public", true);

           if (error) {
             console.error("Error fetching tags:", error);
           } else {
             setItems(data);
           }
         } else {
           const { data, error } = await supabase
             .from("books")
             .select(filterType === "Book" ? "name" : "author");
           if (error) {
             console.error(`Error fetching ${filterType.toLowerCase()}s:`, error);
           } else {
             const mappedItems =
               filterType === "Book"
                 ? [...new Set(data.map((item) => item.name))]
                 : [
                     ...new Set(
                       data.filter((item) => item.author !== null).map((item) => item.author),
                     ),
                   ];
             setItems(mappedItems);
           }
         }
       };

       fetchItems();
     }, [filterType, user]);

     const filteredItems = items.filter((item) => {
       if (filterType === "Owner") {
         return !selectedFilters.some(
           (filter) => filter.type === filterType && filter.value === item.id,
         );
       } else if (filterType === "Tag") {
         return !selectedFilters.some(
           (filter) => filter.type === filterType && filter.value === item.name,
         );
       }
       return !selectedFilters.some(
         (filter) => filter.type === filterType && filter.value === item,
       );
     });

     const filterLabels = {
       Book: "book title",
       Author: "author",
       Owner: "owner",
       Time: "max time (minutes)",
       Tag: "tag",
     };

     return (
       <div style={{ display: "flex", justifyContent: "flex-end" }}>
         {filterType === "Time" ? (
           <TextField
             sx={{
               width: "60%",
               "& .MuiOutlinedInput-root": {
                 fontFamily: theme.typography.fontFamily,
               },
               "& .MuiInputLabel-outlined": {
                 fontFamily: theme.typography.fontFamily,
               },
             }}
             label={filterLabels[filterType]}
             variant="outlined"
             value={inputValue}
             onChange={(e) => setInputValue(e.target.value)}
             onKeyDown={(e) => {
               if (e.key === "Enter" && inputValue && !isNaN(inputValue)) {
                 updateFilters("Time", inputValue);
                 setInputValue("");
                 e.target.blur();
               }
             }}
             InputProps={{
               startAdornment: (
                 <>
                   <select
                     value={filterType}
                     onChange={(e) => setFilterType(e.target.value)}
                     style={{
                       border: "none",
                       background: "transparent",
                       marginRight: theme.spacing(1),
                       fontFamily: theme.typography.fontFamily,
                       color: theme.palette.text.primary,
                     }}
                     onMouseDown={(e) => e.stopPropagation()}
                     onClick={(e) => e.stopPropagation()}
                   >
                     {filterTypes.map(
                       (type) =>
                         !(type === "Owner" && !user) && (
                           <option key={type} value={type}>
                             {type.toLowerCase()}
                           </option>
                         ),
                     )}
                   </select>
                 </>
               ),
             }}
           />
         ) : (
           <Autocomplete
             sx={{ width: "60%" }}
             options={filteredItems}
             groupBy={
               filterType === "Tag"
                 ? (option) => (option.category && option.category.name) || "Uncategorized"
                 : undefined
             }
             getOptionLabel={(option) => {
               if (filterType === "Owner") {
                 return option.email;
               } else if (filterType === "Tag") {
                 return option.name;
               }
               return option;
             }}
             value={autocompleteValue}
             inputValue={inputValue}
             onChange={(event, newValue) => {
               if (filterType === "Owner") {
                 updateFilters(filterType, newValue.id);
               } else if (filterType === "Tag") {
                 updateFilters(filterType, newValue.name);
               } else {
                 updateFilters(filterType, newValue);
               }
               setAutocompleteValue(null);
               setInputValue("");

               if (inputRef.current) {
                 inputRef.current.blur();
               }
             }}
             onInputChange={(event, newInputValue) => {
               setInputValue(newInputValue);
             }}
             renderOption={(props, option) => {
               if (filterType === "Owner") {
                 return (
                   <li {...props}>
                     <Avatar
                       src={option?.user_metadata?.avatar_url}
                       alt={option.email}
                       sx={{
                         width: 30,
                         height: 30,
                         marginRight: theme.spacing(1),
                       }}
                     />
                     {option.email}
                   </li>
                 );
               } else if (filterType === "Tag") {
                 return (
                   <li {...props}>
                     <span>{option.name}</span>
                   </li>
                 );
               }
               return <li {...props}>{option}</li>;
             }}
             renderInput={(params) => (
               <TextField
                 {...params}
                 label={filterLabels[filterType] || ""}
                 variant="outlined"
                 fullWidth
                 inputRef={inputRef}
                 sx={{
                   "& .MuiOutlinedInput-root": {
                     fontFamily: theme.typography.fontFamily,
                   },
                   "& .MuiInputLabel-outlined": {
                     fontFamily: theme.typography.fontFamily,
                   },
                 }}
                 InputProps={{
                   ...params.InputProps,
                   startAdornment: (
                     <>
                       <select
                         value={filterType}
                         onChange={(e) => setFilterType(e.target.value)}
                         style={{
                           border: "none",
                           background: "transparent",
                           marginRight: theme.spacing(2),
                           fontFamily: theme.typography.fontFamily,
                           color: theme.palette.text.primary,
                           fontSize: ".8rem",
                         }}
                         onMouseDown={(e) => e.stopPropagation()}
                         onClick={(e) => e.stopPropagation()}
                       >
                         {filterTypes.map(
                           (type) =>
                             !(type === "Owner" && !user) && (
                               <option key={type} value={type}>
                                 {type.toLowerCase()}
                               </option>
                             ),
                         )}
                       </select>
                       {params.InputProps.startAdornment}
                     </>
                   ),
                 }}
               />
             )}
             PaperComponent={({ children }) => (
               <Paper
                 sx={{
                   border: `1px solid ${theme.palette.divider}`,
                   fontFamily: theme.typography.fontFamily,
                   fontSize: "14px",
                   maxHeight: 300,
                   overflow: "auto",
                 }}
                 elevation={0}
               >
                 {children}
               </Paper>
             )}
           />
         )}
       </div>
     );
   }

   export default DynamicFilter;