import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// import SettingsIcon from '@mui/icons-material/Settings';
import HouseholdSettings from './HouseholdSettings';

function SettingsPage() {
  const tabs = ['household', 'profile', 'integrations']; // Other tabs can be added later
  const [selectedTab, setSelectedTab] = useState(tabs[0]); // Default to the first tab

  const renderContent = () => {
    switch (selectedTab) {
      case 'household':
        return <HouseholdSettings />;
      default:
        return <Typography>under construction</Typography>;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, height: '100%' }}>
      {/* Left Sidebar for larger screens */}
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: '150px',
          fontWeight:'bold',
          borderRight: '1px solid lightgrey',
        }}
      >
        <List component="nav">
          {tabs.map((tab) => (
            <ListItem
              button
              key={tab}
              selected={selectedTab === tab}
              onClick={() => setSelectedTab(tab)}
            >
              <ListItemText primary={tab} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          padding: { xs: '8px', sm: '8px 16px' },
        }}
      >
        {renderContent()}
      </Box>

      {/* Bottom Navigation for mobile */}
      <BottomNavigation
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <BottomNavigationAction
          label="household"
          value="household"
          icon={<HomeIcon />}
        />
        {/* Add more BottomNavigationAction items here if more tabs are added */}
      </BottomNavigation>
    </Box>
  );
}

export default SettingsPage;
