import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Checkbox,
  Alert,
  Snackbar,
  TextField,
  List,
  ListItem,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from '@mui/material';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './Droppable';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
// import { SendToThings } from './SendToThings';
import supabase from '../supabaseClient';

export function ShoppingList({ listId }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [listItems, setListItems] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [newItemValue, setNewItemValue] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState({ item: null, index: -1 });
  const [showList, setShowList] = useState(false);
  const [inputFieldInitialPosition, setInputFieldInitialPosition] = useState(null);
  const [showFixedInput, setShowFixedInput] = useState(false);
  const [newItemId, setNewItemId] = useState(null);

  const editingRef = useRef(null);
  const newItemRef = useRef(null);
  const caretPositionRef = useRef(null);
  const startOfListRef = useRef(null);
  const itemRefs = useRef({});

  useEffect(() => {
    if (editingRef.current) {
      editingRef.current.focus();
      const input = editingRef.current;
      if (caretPositionRef.current !== null && input.setSelectionRange) {
        const position = caretPositionRef.current;
        input.setSelectionRange(position, position);
      }
    }
  }, [editingId]);

  const handleEdit = (e, id) => {
    const item = listItems.find((item) => item.id === id);
    setEditingId(id);
    setEditingValue(item.name);

    // Get the click position
    const clickX = e.clientX;
    const clickY = e.clientY;

    // Calculate caret position
    let caretPosition = 0;

    // Get the caret position within the element
    if (document.caretPositionFromPoint) {
      // Firefox
      const position = document.caretPositionFromPoint(clickX, clickY);
      if (position) {
        caretPosition = position.offset;
      }
    } else if (document.caretRangeFromPoint) {
      // Chrome, Safari
      const range = document.caretRangeFromPoint(clickX, clickY);
      if (range) {
        caretPosition = range.startOffset;
      }
    }

    caretPositionRef.current = caretPosition;
  };

  const handleUpdate = async (id) => {
    const updatedItems = listItems.map((item) =>
      item.id === id ? { ...item, name: editingValue } : item
    );
    setListItems(updatedItems);
    setEditingId(null);
    setEditingValue('');
    caretPositionRef.current = null;

    try {
      const { error } = await supabase
        .from('lists_shopping_list')
        .update({ ingredient_json: updatedItems })
        .eq('list_id', listId);
      if (error) throw error;
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleCheckboxChange = async (id) => {
    const updatedItems = listItems.map((item) =>
      item.id === id ? { ...item, checkbox: !item.checkbox } : item
    );
    setListItems(updatedItems);

    try {
      const { error } = await supabase
        .from('lists_shopping_list')
        .update({ ingredient_json: updatedItems })
        .eq('list_id', listId);
      if (error) throw error;
    } catch (error) {
      console.error('Error updating checkbox:', error);
    }
  };

  useEffect(() => {
    if (!listId) return;

    // Fetch initial items
    const fetchItems = async () => {
      const { data, error } = await supabase
        .from('lists_shopping_list')
        .select('ingredient_json')
        .eq('list_id', listId);

      if (error) {
        console.error('Error fetching items:', error);
        return;
      }

      if (data && data[0]?.ingredient_json) {
        setListItems(data[0].ingredient_json);
        setShowList(true);
      } else {
        setShowList(false);
      }
    };
    fetchItems();

    // Subscribe to realtime updates
    const channel = supabase
      .channel(`public:lists_shopping_list:list_id=eq.${listId}`)
      .on(
        'postgres_changes',
        { event: 'UPDATE', schema: 'public', table: 'lists_shopping_list', filter: `list_id=eq.${listId}` },
        (payload) => {
          if (payload.new && payload.new.ingredient_json) {
            setListItems(payload.new.ingredient_json);
          }
        }
      )
      .subscribe();

    // Cleanup subscription on unmount
    return () => {
      supabase.removeChannel(channel);
    };
  }, [listId]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedItems = [...listItems];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setListItems(reorderedItems);

    try {
      const { error } = await supabase
        .from('lists_shopping_list')
        .update({ ingredient_json: reorderedItems })
        .eq('list_id', listId);
      if (error) throw error;
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const deleteItem = async (id) => {
    const indexToDelete = listItems.findIndex((item) => item.id === id);
    const itemToDelete = listItems[indexToDelete];

    const updatedItems = listItems.filter((item) => item.id !== id);
    setListItems(updatedItems);
    setSnackbarOpen(true);

    setDeletedItem({ item: itemToDelete, index: indexToDelete });

    try {
      const { error } = await supabase
        .from('lists_shopping_list')
        .update({ ingredient_json: updatedItems })
        .eq('list_id', listId);
      if (error) throw error;
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const addNewItem = async (itemName = '') => {
    if (!itemName.trim()) return null;
    const newItem = {
      id: uuidv4(),
      name: itemName.trim(),
      checkbox: false,
    };

    const updatedItems = [...listItems, newItem];
    setListItems(updatedItems);
    setNewItemId(newItem.id); // Store the new item's ID

    try {
      const { error } = await supabase
        .from('lists_shopping_list')
        .update({ ingredient_json: updatedItems })
        .eq('list_id', listId);
      if (error) throw error;
    } catch (error) {
      console.error('Error adding new item:', error);
    }

    return newItem.id;
  };

  const undoDelete = async () => {
    if (deletedItem.item && deletedItem.index > -1) {
      const restoredList = [...listItems];
      restoredList.splice(deletedItem.index, 0, deletedItem.item);
      setListItems(restoredList);

      try {
        const { error } = await supabase
          .from('lists_shopping_list')
          .update({ ingredient_json: restoredList })
          .eq('list_id', listId);
        if (error) throw error;
      } catch (error) {
        console.error('Error restoring item:', error);
      }
    }

    closeSnackbar();
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
    setDeletedItem(null);
  };

  const handleInputFocus = () => {
    if (newItemRef.current) {
      const rect = newItemRef.current.getBoundingClientRect();
      setInputFieldInitialPosition({ top: rect.top });
    }
  };

  const handleInputBlur = () => {
    setInputFieldInitialPosition(null);
  };

  useEffect(() => {
    if (inputFieldInitialPosition && newItemRef.current) {
      const rect = newItemRef.current.getBoundingClientRect();
      const deltaY = rect.top - inputFieldInitialPosition.top;
      window.scrollBy(0, deltaY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems.length]);

  // Scroll the new item into view after it's added
  useEffect(() => {
    if (newItemId && itemRefs.current[newItemId]) {
      itemRefs.current[newItemId].scrollIntoView({ behavior: 'smooth', block: 'center' });
      setNewItemId(null);
    }
  }, [listItems, newItemId]);

  // Effect to handle showing the fixed input field when user scrolls into the list on mobile
  useEffect(() => {
    if (!isMobile) {
      setShowFixedInput(false);
      return;
    }

    const handleScroll = () => {
      if (!startOfListRef.current) return;
      const rect = startOfListRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Show fixed input if list is scrolled into the viewport (adjust threshold as needed)
      if (rect.top < viewportHeight - 100) {
        setShowFixedInput(true);
      } else {
        setShowFixedInput(false);
      }
    };

    // Introduce a delay before adding the scroll event listener
    const delayTimeout = setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initialize on mount after delay
    }, 1000); // Delay in milliseconds (e.g., 1000ms = 1 second)

    return () => {
      clearTimeout(delayTimeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <div>
      {showList && (
        <div>
          <Typography
            variant="overline"
            sx={{
              fontFamily: theme.typography.fontFamily,
              textTransform: 'lowercase',
              fontSize: '1.1rem',
              letterSpacing: 0,
            }}
          >
            shopping list
          </Typography>
        </div>
      )}

      <div>
        {/* Reference point to determine scroll position */}
        <div ref={startOfListRef}></div>

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided) => (
              <List
                sx={{
                  padding: 0,
                  ...(isMobile ? { paddingBottom: '1em' } : {}),
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {listItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={(el) => {
                          provided.innerRef(el);
                          if (el) {
                            itemRefs.current[item.id] = el; // Store the ref
                          } else {
                            delete itemRefs.current[item.id]; // Clean up
                          }
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItem
                          sx={{
                            padding: '.25em .5em 0 .5em',
                            marginBottom: 0,
                            borderRadius: '.5em',
                            backgroundColor: snapshot.isDragging
                              ? theme.palette.action.hover
                              : 'inherit',
                            border: snapshot.isDragging
                              ? `1px solid ${theme.palette.divider}`
                              : '',
                            color: snapshot.isDragging ? theme.palette.text.primary : '',
                          }}
                          onClick={(e) => handleEdit(e, item.id)}
                        >
                          <Checkbox
                            checked={item.checkbox}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                              handleCheckboxChange(item.id);
                            }}
                            sx={{
                              alignSelf: 'flex-start',
                              padding: '.25em .5em 0 0',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              width: '100%',
                            }}
                          >
                            {editingId === item.id ? (
                              <TextField
                                multiline
                                variant="standard"
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: 0,
                                    fontSize: '14px',
                                  },
                                  '& .MuiInputBase-input::placeholder': {
                                    fontSize: '14px',
                                  },

                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                  },
                                  alignSelf: 'flex-start',
                                  fontFamily: theme.typography.fontFamily,
                                }}
                                InputProps={{
                                  inputRef: editingRef,
                                }}
                                fullWidth
                                value={editingValue}
                                onChange={(e) => setEditingValue(e.target.value)}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleUpdate(item.id);
                                  }
                                }}
                                autoFocus
                                onBlur={() => {
                                  handleUpdate(item.id);
                                }}
                              />
                            ) : (
                              <Typography
                                variant="body1"
                                onClick={(e) => handleEdit(e, item.id)}
                                sx={{
                                  cursor: 'pointer',
                                  flexGrow: 1,
                                  whiteSpace: 'pre-wrap',
                                  padding: '.35em 0 .25em 0',
                                  fontFamily: theme.typography.fontFamily,
                                  fontSize: '14px',
                                }}
                              >
                                {item.name}
                              </Typography>
                            )}
                            <IconButton
                              edge="end"
                              type="button"
                              sx={{
                                flexShrink: 1,
                                display: 'flex',
                                margin: '0 0 0 .25em',
                                padding: '.25em',
                                alignSelf: 'flex-start',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteItem(item.id);
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  flexShrink: 1,
                                  alignSelf: 'flex-start',
                                  color: theme.palette.text.secondary,
                                }}
                              />
                            </IconButton>
                          </div>
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        {/* Conditionally render the input field based on device */}
        {isMobile ? (
          editingId === null ? (
            // Mobile version: Fixed input field at the bottom
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: theme.palette.background.actions,
                padding: '0.5em',
                borderTop: `1px solid ${theme.palette.primary.contrastText}`,
                zIndex: 1000,
                display: showFixedInput ? 'flex' : 'none', // Show only when scrolled into the list
                justifyContent: 'center',
              }}
            >
              <div style={{ maxWidth: '400px', width: '100%', padding: '0 1em' }}>
                <TextField
                  value={newItemValue}
                  onChange={(e) => setNewItemValue(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onKeyPress={async (e) => {
                    if (e.key === 'Enter') {
                      await addNewItem(newItemValue);
                      setNewItemValue('');
                      if (newItemRef.current) {
                        newItemRef.current.focus();
                      }
                    }
                  }}
                  placeholder="add item"
                  variant="standard"
                  fullWidth
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    '& .MuiInputBase-input': {
                      padding: 0,
                      fontSize: '16px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '14px',
                    },
                    '.MuiInput-underline': {
                      borderBottom: theme.palette.primary.main,
                    },
                    '& .MuiInput-root': {
                      '&:before': {
                        borderColor: theme.palette.primary.dark,
                        borderWidth: '2px',
                      },
                    },
                  }}
                  inputRef={newItemRef}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={async () => {
                          await addNewItem(newItemValue);
                          setNewItemValue('');
                          if (newItemRef.current) {
                            newItemRef.current.focus();
                          }
                        }}
                        edge="end"
                        disabled={!newItemValue.trim()}
                      >
                        <AddIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
          ) : null
        ) : (
          // Desktop version: Original input field placement
          <div style={{ marginTop: '' }}>
            <TextField
              value={newItemValue}
              onChange={(e) => setNewItemValue(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onKeyPress={async (e) => {
                if (e.key === 'Enter') {
                  await addNewItem(newItemValue);
                  setNewItemValue('');
                  if (newItemRef.current) {
                    newItemRef.current.focus();
                  }
                }
              }}
              placeholder="add item"
              variant="standard"
              fullWidth
              sx={{
                fontFamily: theme.typography.fontFamily,
                padding: '0 1em 0 .5em',
                '& .MuiInputBase-input': {
                  padding: 0,
                  fontSize: '16px',
                },
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '14px',
                },
              }}
              inputRef={newItemRef}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      await addNewItem(newItemValue);
                      setNewItemValue('');
                      if (newItemRef.current) {
                        newItemRef.current.focus();
                      }
                    }}
                    edge="end"
                    disabled={!newItemValue.trim()}
                  >
                    <AddIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        )}
      </div>

      {showList && (
        <>
          {/* <Divider sx={{ opacity: 0.4, margin: !isMobile ? '2em 0 0 0' : '1em 0 0 0' }} /> */}
          <Box sx={{display:'flex',justifyContent:'left', margin: '0 0 4em 0'}}>
          {/* <SendToThings listItems={listItems} /> */}
          </Box>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity="success"
          variant="filled"
          action={
            <Button color="secondary" size="small" onClick={undoDelete}>
              UNDO
            </Button>
          }
        >
          Item deleted
        </Alert>
      </Snackbar>
    </div>
  );
}