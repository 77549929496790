import { createContext, useState, useEffect, useContext } from "react";
import supabase from "../supabaseClient";
import UserContext from "./userContext";

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const {user} = useContext(UserContext)
  const [favoritedRecipes, setFavoritedRecipes] = useState([]);
  const [defaultListId, setDefaultListId] = useState(null);
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Fetch default list ID
  useEffect(() => {
    if (user) {
      const fetchDefaultListId = async () => {
        const { data: listData, error: listError } = await supabase
          .from("saved_lists")
          .select("list_id")
          .eq("user_id", user.id)
          .eq("list_name", "favorites");

        if (!listError && listData && listData.length > 0) {
          setDefaultListId(listData[0].list_id);
        }
      };

      fetchDefaultListId();
    }
  }, [user]);

  // Fetch favorited recipes
  useEffect(() => {
    if (user && defaultListId) {
      const fetchFavorites = async () => {
        const { data, error } = await supabase
          .from("lists_recipes")
          .select("recipe_id")
          .eq("list_id", defaultListId);

        if (!error) {
          setFavoritedRecipes(data.map((d) => d.recipe_id));
        }
      };

      fetchFavorites();
    }
  }, [user, defaultListId]);

  // Handle favorite toggle
  const handleFavorite = async (event, recipeId) => {
    event.stopPropagation();

    if (!user) {
      setSnackbarMessage("please log in to favorite recipes");
      setSnackbarOpen(true);
      return;
    }

    const currentFavoritedStatus = favoritedRecipes.includes(recipeId);
    setFavoritedRecipes((prevState) =>
      currentFavoritedStatus
        ? prevState.filter((id) => id !== recipeId)
        : [...prevState, recipeId]
    );

    try {
      await supabase.rpc("toggle_recipe_in_default_list", {
        recipe_id_param: recipeId,
        user_id_param: user.id,
      });
    } catch (e) {
      console.error("Error toggling favorite:", e);
      setFavoritedRecipes((prevState) =>
        currentFavoritedStatus
          ? [...prevState, recipeId]
          : prevState.filter((id) => id !== recipeId)
      );
    }
  };

  // Handle add to list
  const handleAddToList = (e, recipeId) => {
    e.stopPropagation();

    if (!user) {
      setSnackbarMessage("please log in to add recipes to your list");
      setSnackbarOpen(true);
      return;
    }

    setSelectedRecipeId(recipeId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRecipeId(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleShare = async (event, recipeId) => {
    event.stopPropagation();
      setSnackbarMessage("sharing not yet available. sorry :)");
      setSnackbarOpen(true);
      return;
  }

  return (
    <FavoritesContext.Provider
      value={{
        favoritedRecipes,
        handleFavorite,
        handleAddToList,
        openDialog,
        selectedRecipeId,
        handleCloseDialog,
        snackbarOpen,
        snackbarMessage,
        handleSnackbarClose,
        handleShare
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
