import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import UserContext from './userContext';

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined); // Start with undefined to indicate loading
  const [session, setSession] = useState(undefined); // Start with undefined

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error('Error fetching session:', error);
        setUser(null);
        setSession(null);
        return;
      }

      if (session?.user && session?.access_token) {
        // Decode the JWT to get user_role
        const decodedToken = jwtDecode(session.access_token);
        const userRole = decodedToken.user_role || 'user';
        setUser({ ...session.user, user_role: userRole });
        setSession(session);
      } else {
        setUser(null); // Explicitly set to null if not authenticated
        setSession(null);
      }
    };

    getSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user && session?.access_token) {
        const decodedToken = jwtDecode(session.access_token);
        const userRole = decodedToken.user_role || 'user';
        setUser({ ...session.user, user_role: userRole });
        setSession(session);
      } else {
        setUser(null);
        setSession(null);
      }
    });

    return () => subscription?.unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, session }}>
      {children}
    </UserContext.Provider>
  );
};

export default AuthProvider;