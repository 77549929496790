// src/App.js

import React, { useState, useMemo } from "react";
import SearchRecipes from "./Search/SearchRecipes";
import Header from "./Header";
import { ThemeProvider } from "@mui/material/styles";
import { Container, CssBaseline, Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./Login/Login";
import ListsPage from "./Lists/ListsPage";
import BooksPage from "./Books/BooksPage";
import UploadRecipe from "./Upload/AdminUploadRecipe";
import RequireAuth from "./RequireAuth";
import PrivacyPolicy from "./PrivacyPolicy";
import { FavoritesProvider } from "./contexts/FavoritesContext";
import getTheme from "./theme";
import NotificationSnackbar from "./NotificationSnackbar";
import CommonDialogs from "./CommonDialogs";
import RecipeEditor from "./Recipes/RecipeEditor";
import UserUploadRecipe from "./Upload/UserUploadRecipe";
import AuthProvider from "./contexts/AuthProvider";
import AcceptInvitation from "./Households/AcceptInvitation";
import SettingsPage from "./Settings/SettingsPage";
import MealPlanPage from "./MealPlan/MealPlan";

function App() {
  const [themeMode, setThemeMode] = useState("dark"); // Theme mode state

  // Load theme mode from localStorage on initial render
  React.useEffect(() => {
    const savedTheme = localStorage.getItem("themeMode");
    if (savedTheme) {
      setThemeMode(savedTheme);
    }
  }, []);

  // Memoize the theme object based on the theme mode
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);

  // Function to toggle the theme mode
  const toggleTheme = () => {
    setThemeMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", newMode); // Save preference
      return newMode;
    });
  };

  // Update the <meta name="theme-color"> based on theme mode
  React.useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", theme.palette.background.default);
    }
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <FavoritesProvider>
          <Router>
            <Header toggleTheme={toggleTheme} themeMode={themeMode} />
            <Container component="main" maxWidth={false} sx={{ px: 2, mt: 2 }}>
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%", // Full width on extra-small screens
                    sm: 600, // 600px max-width on small screens
                    md: 1300, // 1300px max-width on medium screens and up
                  },
                  mx: "auto", // Center horizontally
                }}
              >
                <Routes>
                  <Route path="/" element={<SearchRecipes />} />
                  <Route path="/login" element={<LoginPage />} />

                  {/* Accept Invitation Route */}
                  <Route
                    path="/accept-invitation"
                    element={<AcceptInvitation />}
                  />

                  {/* Lists route */}
                  <Route
                    path="/lists/*"
                    element={
                      <RequireAuth>
                        <ListsPage />
                      </RequireAuth>
                    }
                  />

                  {/* Books routes - books list and individual book details */}
                  <Route
                    path="/books/*"
                    element={
                      <RequireAuth>
                        <BooksPage />
                      </RequireAuth>
                    }
                  />

                  {/* Upload route - accessible only to 'admin' role */}
                  <Route
                    path="/upload/admin"
                    element={
                      <RequireAuth requiredRole="admin">
                        <UploadRecipe />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <RequireAuth>
                        <UserUploadRecipe />
                      </RequireAuth>
                    }
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/recipes/edit/:id"
                    element={
                      <RequireAuth>
                        <RecipeEditor />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <RequireAuth>
                        <SettingsPage />
                      </RequireAuth>
                    }
                  />
                                  <Route
                  path="/meal-plan"
                  element={
                    <RequireAuth>
                      <MealPlanPage />
                    </RequireAuth>
                  }
                />
                </Routes>
              </Box>
            </Container>
          </Router>
          <NotificationSnackbar />
          <CommonDialogs />
        </FavoritesProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
