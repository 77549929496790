import React from 'react';
import { Button, Typography, Checkbox, useTheme } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AddIcon from '@mui/icons-material/Add';

export function CustomMarkdown({ content, setMarkdownContent, disabled }) {
  const theme = useTheme(); // Access the theme

  const lines = content.split('\n').map((line, index) => {
    let trimmedLine = line.trim();

    // Convert single bracket format to double bracket format
    if (trimmedLine.includes('- [ ]')) trimmedLine = trimmedLine.replace('- [ ]', '- [[ ]]');
    if (trimmedLine.includes('* [ ]')) trimmedLine = trimmedLine.replace('* [ ]', '* [[ ]]');
    if (trimmedLine.includes('- [x]')) trimmedLine = trimmedLine.replace('- [x]', '- [[ x ]]');
    if (trimmedLine.includes('* [x]')) trimmedLine = trimmedLine.replace('* [x]', '- [[ x ]]');

    const isChecked = trimmedLine.includes('[[ x ]]');

    if (
      trimmedLine.startsWith('- [[ ]]') ||
      trimmedLine.startsWith('* [[ ]]') ||
      trimmedLine.startsWith('- [[ x ]]') ||
      trimmedLine.startsWith('* [[ x ]]')
    ) {
      return (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', margin: theme.spacing(1, 0) }}
        >
          <Checkbox
            color="primary"
            checked={isChecked}
            // onChange={() => handleCheckboxChange(index, trimmedLine)}
            disabled={disabled}
            sx={{ padding: theme.spacing(0, 0.5, 0.5, 0.5), alignSelf: 'flex-start' }}
          />
          <Typography
            variant="body1"
            sx={{
              alignSelf: 'flex-start',
              textTransform: 'lowercase',
              fontFamily: theme.typography.fontFamily,
              fontSize: '14px',
              color: theme.palette.text.primary,
            }}
          >
            {trimmedLine
              .replace('- [[ x ]]', '')
              .replace('* [[ x ]]', '')
              .replace('- [[ ]]', '')
              .replace('* [[ ]]', '')
              .trim()}
          </Typography>
        </div>
      );
    }

    return (
      <ReactMarkdown
        key={index}
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => (
            <Typography
              variant="body1"
              sx={{ fontFamily: theme.typography.fontFamily, fontSize: '1.1rem', color: theme.palette.text.primary }}
              {...props}
            />
          ),
          li: ({ node, ...props }) => (
            <li
              style={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '14px',
                color: theme.palette.text.primary,
                marginBottom: theme.spacing(1),
              }}
              {...props}
            />
          ),
          // Add other components as needed
        }}
      >
        {trimmedLine}
      </ReactMarkdown>
    );
  });

  return (
    <div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="overline"
            sx={{
              fontFamily: theme.typography.fontFamily,
              textTransform: 'lowercase',
              fontSize: '1.1rem',
              letterSpacing: 0,
              color: theme.palette.text.primary,
            }}
          >
            shopping list
          </Typography>
          <Button disabled startIcon={<AddIcon />}>
            add item
          </Button>
        </div>
      </div>
      {lines}
    </div>
  );
}