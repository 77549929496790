import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy for Reciply
        </Typography>
        <Typography variant="body1" sx={{fontStyle:'italic'}} paragraph>
          Effective Date: 2024-09-01
        </Typography>
        <Typography variant="body1" paragraph>
          At Reciply, we value and respect your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our recipe app, Reciply. By using Reciply, you agree to the collection and use of information in accordance with this policy.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following types of information to provide and improve our services:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          1.1. Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          When you sign up for an account, we may collect personal information that identifies you, such as:
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Username</li>
            <li>Profile picture (optional)</li>
          </ul>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          1.2. Usage Data
        </Typography>
        <Typography variant="body1" paragraph>
          We automatically collect information on how you interact with Reciply, such as:
          <ul>
            <li>Device information (e.g., device type, operating system)</li>
            <li>IP address</li>
            <li>App usage statistics (e.g., favorite recipes, search history)</li>
            <li>Cookies and other tracking technologies (to personalize your experience)</li>
          </ul>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          1.3. Information You Provide
        </Typography>
        <Typography variant="body1" paragraph>
          We may also collect information you voluntarily provide through the app, such as:
          <ul>
            <li>Custom recipe submissions</li>
            <li>Comments, likes, or interactions with other users’ content</li>
            <li>Feedback and support requests</li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the collected information for the following purposes:
          <ul>
            <li>To provide and maintain our app's core functionalities</li>
            <li>To personalize your experience and recommend recipes based on your preferences</li>
            <li>To communicate with you regarding account-related issues, new features, or promotional offers</li>
            <li>To analyze usage trends to improve our app and user experience</li>
            <li>To respond to your requests and provide customer support</li>
            <li>To prevent fraud, misuse, and ensure the security of our services</li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. How We Share Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal information to third parties. However, we may share your information with trusted third parties for the following purposes:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          3.1. Service Providers
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with third-party service providers who help us operate and maintain the app (e.g., cloud hosting, analytics tools). These third parties are required to keep your information secure and only use it for the purposes of providing services to us.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          3.2. Legal Requirements
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose your information if required to do so by law or if we believe such action is necessary to comply with legal obligations, protect and defend the rights or property of Reciply, or prevent fraud and abuse.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          3.3. Business Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          In the event of a merger, acquisition, or sale of all or a portion of Reciply, your information may be transferred as part of the business assets. We will notify you via email and/or prominent notice in the app if such a transfer occurs.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You have control over your personal information in the following ways:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          4.1. Account Information
        </Typography>
        <Typography variant="body1" paragraph>
          You can review, update, or delete your account information at any time by accessing your account settings in the app.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          4.2. Communication Preferences
        </Typography>
        <Typography variant="body1" paragraph>
          You can opt-out of receiving promotional emails by following the unsubscribe instructions included in these emails or adjusting your preferences in the app settings.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          4.3. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          You can control the use of cookies and tracking technologies through your device settings. Please note that disabling these features may affect your experience with the app.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it. However, please understand that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Reciply is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy in the app and revising the "Effective Date" at the top of this page. Your continued use of Reciply after any changes to this Privacy Policy will constitute your acknowledgment and acceptance of the changes.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Reciply Support</strong>
          <br />
          Email: support@reciply.xyz
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
