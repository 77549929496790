import React from 'react';
import { Box, Typography, Checkbox, useTheme } from '@mui/material';
import RecipeDetail from '../Recipes/RecipeDetail'; // Adjust the import path as necessary

const SelectableRecipe = React.memo(function SelectableRecipe({
  recipeKey,
  recipeTitle,
  recipe,
  selected,
  onChange,
}) { 
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginBottom: theme.spacing(2),
        // Removed border around each recipe
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={selected}
          onChange={(e) => onChange(recipeKey, e.target.checked)}
        />
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            marginLeft: theme.spacing(1),
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {recipeTitle}
        </Typography>
      </Box>
      <RecipeDetail
        recipe={recipe}
        index={0}
        disableExpansion={false}
        compact={true} // Use compact version
      />
    </Box>
  );
});

export default SelectableRecipe;