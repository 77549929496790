// src/Login/Login.js

import React, { useState, useEffect, useContext } from 'react';
import supabase from '../supabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useTheme } from '@mui/material/styles';
import './login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../contexts/userContext';

function LoginPage() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Get user from context

  // Extract 'redirectTo' from query parameters
  const queryParams = new URLSearchParams(location.search);
  const redirectToParam = queryParams.get('redirectTo') || '/';

  // Build the full redirect URL (must be a full URL for Supabase)
  const redirectToFullUrl = `${window.location.origin}${redirectToParam}`;

  console.log('Redirecting to:', redirectToFullUrl);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the user is already logged in and redirect if so
  useEffect(() => {
    if (user) {
      // Redirect to the desired page
      navigate(redirectToParam, { replace: true });
    }
  }, [user, navigate, redirectToParam]);

  const containerStyles = {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: isMobile ? '95vw' : '50vw',
    padding: isMobile
      ? theme.spacing(2)
      : `${theme.spacing(2)} ${theme.spacing(4)}`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.5s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '90vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  };

  const authContainerStyles = {
    width: '100%',
  };

  // Map MUI theme variables to Supabase Auth UI theme variables
  const supabaseTheme = {
    colors: {
      brand: theme.palette.primary.main,
      brandAccent: theme.palette.primary.dark,
      brandButtonText: theme.palette.primary.contrastText,
      defaultButtonBackground: theme.palette.background.actions,
      defaultButtonBackgroundHover: theme.palette.action.hover,
      defaultButtonBorder: theme.palette.divider,
      // defaultButtonText: theme.palette.text.primary,
      dividerBackground: theme.palette.divider,
      inputBackground: theme.palette.background.paper,
      inputBorder: theme.palette.divider,
      inputBorderHover: theme.palette.text.secondary,
      inputBorderFocus: theme.palette.text.primary,
      inputText: theme.palette.text.primary,
      inputPlaceholder: theme.palette.text.secondary,
      messageText: theme.palette.text.secondary,
      messageTextDanger: theme.palette.error.main,
      // anchorTextColor: theme.palette.primary.main,
      // anchorTextHoverColor: theme.palette.primary.dark,
    },
    fonts: {
      bodyFontFamily: theme.typography.fontFamily,
      buttonFontFamily:
        theme.typography.button.fontFamily || theme.typography.fontFamily,
      inputFontFamily: theme.typography.fontFamily,
      labelFontFamily: theme.typography.fontFamily,
    },
    fontSizes: {
      // baseBodySize: theme.typography.body1.fontSize,
      // baseInputSize: theme.typography.body1.fontSize,
      // baseLabelSize: theme.typography.caption.fontSize,
      // baseButtonSize: theme.typography.button.fontSize,
    },
    radii: {
      borderRadiusButton: `${theme.shape.borderRadius}`,
      // buttonBorderRadius: `${theme.shape.borderRadius}`,
      inputBorderRadius: `${theme.shape.borderRadius}`,
    },
    borders: {
      // inputBorderWidth: '1px',
      // buttonBorderWidth: '1px',
    },
    space: {
      // spaceSmall: theme.spacing(0.5),
      // spaceMedium: theme.spacing(1),
      // spaceLarge: theme.spacing(2),
      // labelBottomMargin: theme.spacing(1),
      // anchorBottomMargin: theme.spacing(0.5),
      // emailInputSpacing: theme.spacing(0.5),
      // socialAuthSpacing: theme.spacing(1),
      // buttonPadding: `${theme.spacing(1)} ${theme.spacing(1)}`,
      // inputPadding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    },
  };

  return (
    <div style={containerStyles}>
      <div style={authContainerStyles}>
        <Auth
          redirectTo={redirectToFullUrl}
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              [theme.palette.mode === 'light' ? 'default' : 'dark']:
                supabaseTheme,
            },
          }}
          theme={theme.palette.mode === 'light' ? 'default' : 'dark'}
          providers={['google', 'github']}
          localization={{
            variables: {
              sign_in: {
                email_label: 'email',
                email_input_placeholder: 'email address',
                password_label: 'password',
                password_input_placeholder: 'password',
                social_provider_text: `{{provider}}`,
                button_label: 'sign in',
              },
              forgotten_password: {
                link_text: 'forgot password',
              },
              sign_up: {
                link_text: 'sign up',
              },
              provider_names: {
                google: 'google',
                github: 'github',
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default LoginPage;
