import React from 'react';
import { Skeleton, useTheme } from '@mui/material';

function RecipeExpandedSkeleton() {
  const theme = useTheme();

  return (
    <div style={{ }}>
                  {/* Skeleton Loader */}
                  <div
                    style={{
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '.5em',
                      marginBottom: '1em',
                      backgroundColor: theme.palette.background.paper,
                      overflow: 'hidden',
                    }}
                  >
                    {/* Recipe Content */}
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.75em' }}>
                      {/* Top Content */}
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* Left Column */}
                        <div style={{ flex: 1 }}>
                          {/* Title Skeleton */}
                          <Skeleton variant="text" width="70%" height={30} />
                          {/* Book Name Skeleton */}
                          <Skeleton variant="text" width="50%" height={20} style={{ marginTop: 4 }} />
                          {/* Stats Bar */}
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                            {/* First Stat Skeleton */}
                            <Skeleton variant="circular" width={18} height={18} style={{ marginRight: 8 }} />
                            <Skeleton variant="text" width={30} height={18} style={{ marginRight: 16 }} />
                            {/* Second Stat Skeleton */}
                            <Skeleton variant="circular" width={18} height={18} style={{ marginRight: 8 }} />
                            <Skeleton variant="text" width={30} height={18} style={{ marginRight: 16 }} />
                            {/* Third Stat Skeleton */}
                            <Skeleton variant="circular" width={18} height={18} style={{ marginRight: 8 }} />
                            <Skeleton variant="text" width={30} height={18} />
                          </div>
                        </div>
                        {/* Right Column */}
                        <div style={{ marginLeft: '1em' }}>
                          {/* Image Skeleton */}
                          <Skeleton variant="rectangular" width={75} height={75} style={{ borderRadius: '.5em' }} />
                        </div>
                      </div>
                      {/* Expanded Content Skeleton */}
                      <div style={{ marginTop: '1em' }}>
                        {/* Description Skeleton */}
                        <Skeleton variant="text" width="100%" height={20} style={{ marginBottom: 4 }} />
                        <Skeleton variant="text" width="90%" height={20} style={{ marginBottom: 4 }} />
                        <Skeleton variant="text" width="95%" height={20} style={{ marginBottom: 4 }} />
                        {/* Image Skeleton in expanded content */}
                        <Skeleton variant="rectangular" width="100%" height={400} style={{ borderRadius: '.5em', marginTop: '1em' }} />
                        {/* Instructions Skeleton */}
                        <Skeleton variant="text" width="40%" height={24} style={{ marginTop: '1.5em' }} />
                        {/* List of instructions skeleton */}
                        <Skeleton variant="text" width="100%" height={20} style={{ marginBottom: 4, marginTop: 8 }} />
                        <Skeleton variant="text" width="95%" height={20} style={{ marginBottom: 4 }} />
                        <Skeleton variant="text" width="98%" height={20} style={{ marginBottom: 4 }} />
                        <Skeleton variant="text" width="90%" height={20} style={{ marginBottom: 4 }} />
                        {/* View Original Recipe Button Skeleton */}
                        <Skeleton variant="rectangular" width={160} height={36} style={{ marginTop: '1em', borderRadius: '.25em' }} />
                      </div>
                    </div>
                  </div>
                </div>
  )
}

export default RecipeExpandedSkeleton;