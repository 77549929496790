import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import UserContext from '../contexts/userContext';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
} from '@mui/material';

function AcceptInvitation() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [loading, setLoading] = useState(true);
  const [invitationDetails, setInvitationDetails] = useState(null);
  const [error, setError] = useState('');
  const { user, session } = useContext(UserContext);
  const navigate = useNavigate();

  const [currentHousehold, setCurrentHousehold] = useState(null);
  const [currentHouseholdMembers, setCurrentHouseholdMembers] = useState([]);
  const [isCurrentHouseholdOwner, setIsCurrentHouseholdOwner] = useState(false);

  // State for confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    const fetchInvitationDetails = async () => {
      if (!token) {
        setError('Invalid invitation link.');
        setLoading(false);
        return;
      }

      if (!user) {
        // Redirect to login page with redirectTo parameter
        const redirectTo = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        navigate(`/login?redirectTo=${redirectTo}`);
        return;
      }

      try {

        if (!session) {
          setError('You need to be logged in to view the invitation.');
          setLoading(false);
          return;
        }

        const accessToken = session.access_token;

        // Fetch invitation details from the backend
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/households/invitations/${token}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setInvitationDetails(data);
        } else {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch invitation details.');
          setLoading(false);
          return;
        }

        // Fetch the user's current household status
        // Check if user is part of a household
        const { data: householdUser, error: householdUserError } = await supabase
          .from('households_users')
          .select('household_id, role')
          .eq('user_id', user.id)
          .maybeSingle(); // Use maybeSingle to get null if not found

        if (householdUserError) {
          throw householdUserError;
        }

        if (householdUser) {
          // User is part of a household
          const { data: householdData, error: householdError } = await supabase
            .from('households')
            .select('id, name, owner_id')
            .eq('id', householdUser.household_id)
            .single();
          if (householdError) {
            throw householdError;
          }
          setCurrentHousehold(householdData);
          setIsCurrentHouseholdOwner(householdUser.role === 'owner');

          // Fetch household members
          const { data: members, error: membersError } = await supabase
            .from('households_users')
            .select(
              'user_id, role, profiles ( first_name, last_name, email, avatar_url )'
            )
            .eq('household_id', householdUser.household_id);

          if (membersError) {
            throw membersError;
          }
          setCurrentHouseholdMembers(members);
        } else {
          // User is not part of a household
          setCurrentHousehold(null);
        }
      } catch (error) {
        console.error('Error fetching invitation details:', error);
        setError('An error occurred while fetching the invitation details.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvitationDetails();
  }, [token, user, navigate, session]);

  const handleAcceptInvitation = async () => {
    setLoading(true);
    setError('');

    try {
      // Re-fetch the user's current household status
      const { data: householdUser, error: householdUserError } = await supabase
        .from('households_users')
        .select('household_id')
        .eq('user_id', user.id)
        .maybeSingle();

      if (householdUserError) {
        throw householdUserError;
      }

      if (householdUser) {
        // User is still part of a household
        setError(
          'You must leave your current household before accepting a new invitation.'
        );
        setLoading(false);
        return;
      }

      // Proceed to accept the invitation

      if (!session) {
        setError('You need to be logged in to accept the invitation.');
        setLoading(false);
        return;
      }

      const accessToken = session.access_token;

      // Call the backend API to accept the invitation
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/households/accept-invitation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ token }),
        }
      );

      if (response.ok) {
        setInvitationDetails(null);
        setLoading(false);
        navigate('/settings'); // Redirect to settings page or appropriate page
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to accept the invitation.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
      setError('An error occurred while accepting the invitation.');
      setLoading(false);
    }
  };

  const handleGoToDashboard = () => {
    navigate('/'); // Adjust the path to your main dashboard or home page
  };

  const handleLeaveCurrentHousehold = async () => {
    setLoading(true);
    setError('');

    try {

      if (!session) {
        setError('You need to be logged in.');
        setLoading(false);
        return;
      }

      if (isCurrentHouseholdOwner) {
        // Open confirmation dialog to disband household
        setConfirmDialogOpen(true);
        setLoading(false);
        return;
      } else {
        // Leave the current household
        const { error: leaveError } = await supabase
          .from('households_users')
          .delete()
          .eq('household_id', currentHousehold.id)
          .eq('user_id', user.id);

        if (leaveError) {
          throw leaveError;
        }

        // Update state
        setCurrentHousehold(null);
        setCurrentHouseholdMembers([]);
        setIsCurrentHouseholdOwner(false);

        // Now proceed to accept the invitation
        await handleAcceptInvitation();
      }
    } catch (error) {
      console.error('Error leaving household:', error);
      setError('An error occurred while leaving your current household.');
      setLoading(false);
    }
  };

  const handleDisbandHousehold = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);
    setError('');

    try {
      // Delete all entries in households_users for this household
      const { error: deleteUsersError } = await supabase
        .from('households_users')
        .delete()
        .eq('household_id', currentHousehold.id);

      if (deleteUsersError) {
        throw deleteUsersError;
      }

      // Delete the household itself
      const { error: deleteHouseholdError } = await supabase
        .from('households')
        .delete()
        .eq('id', currentHousehold.id);

      if (deleteHouseholdError) {
        throw deleteHouseholdError;
      }

      // Update state
      setCurrentHousehold(null);
      setCurrentHouseholdMembers([]);
      setIsCurrentHouseholdOwner(false);

      // Now proceed to accept the invitation
      await handleAcceptInvitation();
    } catch (error) {
      console.error('Error disbanding household:', error);
      setError('An error occurred while disbanding your current household.');
      setLoading(false);
    }
  };

  const handleCancelDisband = () => {
    setConfirmDialogOpen(false);
  };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <CircularProgress />
        <Typography variant="h6" mt={2}>
          loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoToDashboard}
          sx={{ mt: 2 }}
        >
          go to dashboard
        </Button>
      </Box>
    );
  }

  if (currentHousehold) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h5" align="center">
          you are currently {isCurrentHouseholdOwner ? 'an owner' : 'a member'} of
          the household:
        </Typography>
        <Typography variant="h4" align="center" sx={{ mt: 2 }}>
          {currentHousehold.name}
        </Typography>
        <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
          household members:
        </Typography>
        <List sx={{ width: '100%', maxWidth: 360 }}>
          {currentHouseholdMembers.map((member) => (
            <ListItem key={member.user_id}>
              <Avatar
                src={member.profiles?.avatar_url || ''}
                alt={`${member.profiles?.first_name || 'Unknown'} ${
                  member.profiles?.last_name || 'User'
                }`}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={
                  member.profiles
                    ? `${member.profiles.first_name} ${member.profiles.last_name}`
                    : 'Unknown User'
                }
                secondary={member.role}
              />
            </ListItem>
          ))}
        </List>
        {isCurrentHouseholdOwner ? (
          <>
            <Typography sx={{ mt: 2 }}>
              as the owner, you can disband your current household ("{currentHousehold.name}") to join a new
              one. this action will remove all members and delete the household. <strong>you and your current members will lose access to shared resources, including recipes and lists.</strong>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLeaveCurrentHousehold}
              sx={{ mt: 2 }}
            >
              disband current household and accept invitation
            </Button>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2 }}>
              you must leave your current household before accepting a new
              invitation. you will lose access to shared resources, including recipes and lists.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLeaveCurrentHousehold}
              sx={{ mt: 2 }}
            >
              leave current household and accept invitation
            </Button>
          </>
        )}

        {/* Confirmation Dialog for Disbanding Household */}
        <Dialog
          open={confirmDialogOpen}
          onClose={handleCancelDisband}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">
            confirm disband household
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              are you sure you want to disband your current household ("
              {currentHousehold.name}")? this action cannot be undone. 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDisband}>cancel</Button>
            <Button onClick={handleDisbandHousehold} color="secondary" autoFocus>
              disband household
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Typography variant="h6" >
        you have been invited to join the household:
      </Typography>
      <Typography variant="h4" align="center" sx={{ mt: 2 }}>
        {invitationDetails.household_name}
      </Typography>
      <Typography variant="h6" sx={{alignSelf:"left"}} mt={2}>
        invited by:
      </Typography>
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
        <Avatar
          src={invitationDetails.invited_by.avatar_url || ''}
          alt={`${invitationDetails.invited_by.first_name || 'Unknown'} ${
            invitationDetails.invited_by.last_name || 'User'
          }`}
          sx={{ mr: 2, width: 56, height: 56 }}
        />
        
        <Typography variant="subtitle1">
          {invitationDetails.invited_by.first_name}{' '}
          {invitationDetails.invited_by.last_name}
          <br />
          {invitationDetails.invited_by.email}
        </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleAcceptInvitation}
        sx={{ mt: 4 }}
      >
        accept invitation
      </Button>
    </Box>
  );
}

export default AcceptInvitation;