import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  Chip,
  Button,
  Grid,
  Pagination,
  useTheme,
  Container,
  useMediaQuery,
  Box,
  Link
} from "@mui/material";
import RecipeList from "../Recipes/RecipeList";
import SearchBar from "./SearchBar";
import { useNavigate, useLocation } from "react-router-dom";
import DynamicFilter from "./DynamicFilter";
import supabase from "../supabaseClient";
import SelectedRecipeDetail from "../Recipes/SelectedRecipeDetail";
import RecipeExpandedSkeleton from "../Recipes/Skeletons/RecipeExpandedSkeleton";
import UserContext from "../contexts/userContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function SearchRecipes() {
  const {user} = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNewSearchLoading, setIsNewSearchLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showRecipes, setShowRecipes] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const ITEMS_PER_PAGE = 10;

  const navigate = useNavigate();
  const location = useLocation();
  const recipeListRef = useRef(null);

  // Extract values directly from URL
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query") || "";
  const books = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("books")
      ? decodeURIComponent(queryParams.get("books")).split(";;")
      : [];
  }, [location.search]);

  const authors = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("authors")
      ? decodeURIComponent(queryParams.get("authors")).split(";;")
      : [];
  }, [location.search]);

  // Extract tags from URL (using tag names)
  const tags = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("tags")
      ? decodeURIComponent(queryParams.get("tags")).split(";;")
      : [];
  }, [location.search]);

  const timeLimit = queryParams.get("time") || ""; // Time limit query parameter
  const ownerId = queryParams.get("owner_id") || ""; // Owner ID filter

  const currentPage = Number(queryParams.get("page") || "1");

  // Build filters object
  const filters = {
    books,
    authors,
    tags,
    time: timeLimit,
    owner: ownerId,
  };

  const selectedFilters = [
    ...books.map((book) => ({ type: "Book", value: book })),
    ...authors.map((author) => ({ type: "Author", value: author })),
    ...tags.map((tagName) => ({ type: "Tag", value: tagName })),
    ...(timeLimit ? [{ type: "Time", value: timeLimit }] : []),
    ...(ownerId ? [{ type: "Owner", value: ownerId }] : []),
  ];

  const updateURL = useCallback(
    (currentQuery, currentFilters, currentPage) => {
      const queryParams = new URLSearchParams();

      // Always set 'query' parameter, even if it's an empty string
      queryParams.set("query", currentQuery);

      if (currentFilters.books.length) {
        queryParams.append("books", currentFilters.books.join(";;"));
      }
      if (currentFilters.authors.length) {
        queryParams.append("authors", currentFilters.authors.join(";;"));
      }
      if (currentFilters.tags.length) {
        queryParams.append("tags", currentFilters.tags.join(";;")); // Include tag names in URL
      }
      if (currentFilters.time) {
        queryParams.append("time", currentFilters.time);
      }
      if (currentFilters.owner) {
        queryParams.append("owner_id", currentFilters.owner);
      }
      if (currentPage > 1) {
        queryParams.append("page", currentPage.toString());
      }

      navigate(`?${queryParams.toString()}`);
    },
    [navigate]
  );

  const parseSearchQuery = (query) => {
    const regex = /([^\s"]+)|"([^"]+)"/g;
    const terms = [];
    let match;

    while ((match = regex.exec(query)) !== null) {
      terms.push(match[2] ? match[2] : match[1]);
    }
    return terms;
  };

  // Initialize prevSearchParamsRef to null
  const prevSearchParamsRef = useRef(null);

  useEffect(() => {
    const searchTerms = parseSearchQuery(query);

    const currentSearchParams = {
      query,
      books,
      authors,
      tags,
      timeLimit,
      ownerId,
    };

    // Determine if it's a new search
    const isNewSearch = () => {
      const prev = prevSearchParamsRef.current;
      if (prev === null) {
        return true; // First render or no previous search parameters
      }
      return (
        prev.query !== currentSearchParams.query ||
        JSON.stringify(prev.books) !== JSON.stringify(currentSearchParams.books) ||
        JSON.stringify(prev.authors) !==
          JSON.stringify(currentSearchParams.authors) ||
        JSON.stringify(prev.tags) !== JSON.stringify(currentSearchParams.tags) ||
        prev.timeLimit !== currentSearchParams.timeLimit ||
        prev.ownerId !== currentSearchParams.ownerId
      );
    };

    const newSearch = isNewSearch(); // Determine if it's a new search before fetching data

    const handleSearch = async () => {
      if (
        !query &&
        books.length === 0 &&
        authors.length === 0 &&
        tags.length === 0 &&
        !timeLimit &&
        !ownerId
      ) {
        return;
      }
      setIsNewSearchLoading(newSearch);
      setLoading(true);
      setHasSearched(true);
      await fetchData(searchTerms, newSearch); // Pass newSearch flag to fetchData
    };

    const fetchData = async (searchTerms, isNewSearchFlag) => {
      const queryParams = new URLSearchParams();

      if (searchTerms.length > 0) {
        queryParams.append("ingredients", searchTerms.join(","));
      }
      if (books.length > 0) {
        queryParams.append("book_names", books.join(";;"));
      }
      if (authors.length > 0) {
        queryParams.append("book_authors", authors.join(";;"));
      }
      if (tags.length > 0) {
        queryParams.append("tags", tags.join(";;")); // Include tag names in fetch
      }
      queryParams.append("page_limit", ITEMS_PER_PAGE);
      queryParams.append("page_offset", (currentPage - 1) * ITEMS_PER_PAGE);
      if (timeLimit) {
        queryParams.append("time_limit", parseInt(timeLimit));
      }
      if (ownerId) {
        queryParams.append("owner_id", ownerId);
      }

      try {
        // Get the access token
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const accessToken = session?.access_token;

        // Build headers conditionally
        const headers = {};
        if (accessToken) {
          headers["Authorization"] = `Bearer ${accessToken}`;
        }

        const response = await fetch(
          `${SERVER_URL}/search?${queryParams.toString()}`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error("Network error while fetching recipes");
        }

        const responseData = await response.json();

        setTotalCount(responseData.total_count);
        setRecipes(responseData.results);
        setLoading(false);
        setShowRecipes(true);
        setHasSearched(true);
        setIsNewSearchLoading(false);

        // Set selectedRecipe only if it's a new search and we have results
        if (isNewSearchFlag && responseData.results.length > 0) {
          setSelectedRecipe(responseData.results[0]);
        }

        // Update previous search parameters after fetching data
        prevSearchParamsRef.current = currentSearchParams;
      } catch (error) {
        console.error("Error fetching recipes:", error);
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    handleSearch();
  }, [query, books, authors, tags, timeLimit, ownerId, currentPage]);

  const handlePageChange = (event, value) => {
    updateURL(query, filters, value);
  };

  useEffect(() => {
    if (recipeListRef.current) {
      if (isMobile) {
        // Scroll the window to the position of the RecipeList
        const rect = recipeListRef.current.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const offsetTop = rect.top + scrollTop - 10; // Adjust offset as needed

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      } else {
        // Scroll the RecipeList container
        recipeListRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [currentPage, recipes, isMobile]);

  const updateFilters = (type, value, isDelete = false) => {
    const updatedFilters = { ...filters };

    if (type === "Book") {
      updatedFilters.books = isDelete
        ? filters.books.filter((book) => book !== value)
        : [...filters.books, value];
    } else if (type === "Author") {
      updatedFilters.authors = isDelete
        ? filters.authors.filter((author) => author !== value)
        : [...filters.authors, value];
    } else if (type === "Tag") {
      updatedFilters.tags = isDelete
        ? filters.tags.filter((tagName) => tagName !== value)
        : [...filters.tags, value]; // value is tag name
    } else if (type === "Time") {
      updatedFilters.time = isDelete ? "" : value;
    } else if (type === "Owner") {
      updatedFilters.owner = isDelete ? "" : value;
    }

    updateURL(query, updatedFilters, 1);
  };

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  // Define showResultsText
  const showResultsText = !loading && recipes.length > 0;

  return (
    <Grid
      container
      sx={{
        height: !isMobile ? "calc(100vh - 90px)" : "",
        display: !hasSearched ? "flex" : "",
        justifyContent: !hasSearched ? "center" : "",
      }}
    >
      {/* Before Search */}
      {!hasSearched && (
        <Grid
          item
          xs={12}
          sx={{
            position: "absolute",
            top: "45%",
            width: !isMobile ? "80vw" : "100%",
            maxWidth: "800px",
            padding: "0 1em",
          }}
        >
          <SearchBar
            query={query}
            setQuery={(newQuery) => updateURL(newQuery, filters, 1)}
            handleSearch={() => updateURL(query, filters, 1)}
          />
          <Grid
            container
            item
            xs={12}
            spacing={1}
            sx={{ marginTop: theme.spacing(1) }}
          >
            {selectedFilters.map((filter, index) => (
              <Chip
                key={index}
                label={
                  <span>
                    {filter.type.toLowerCase()}:{" "}
                    <i>
                      {filter.type === "Time"
                        ? `< ${filter.value} minutes`
                        : filter.type === "Owner" &&
                          user &&
                          filter.value === user.id
                        ? user.email
                        : filter.value}
                    </i>
                  </span>
                }
                onDelete={() => {
                  if (filter.type === "Time") {
                    updateFilters(filter.type, "", true);
                  } else {
                    updateFilters(filter.type, filter.value, true);
                  }
                }}
                sx={{
                  borderRadius: ".5em",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  margin: theme.spacing(0, 0.5, 0.5, 0),
                }}
              />
            ))}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              size="small"
              sx={{
                marginLeft: "auto",
                textTransform: "none",
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.main,
              }}
              onClick={() => setShowFilters(!showFilters)}
            >
              + filter
            </Button>
          </Box>
          {showFilters && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  width: isMobile ? "100%" : "80%",
                  margin: "0 auto",
                }}
              >
                <DynamicFilter
                  updateFilters={updateFilters}
                  selectedFilters={selectedFilters}
                />
              </Grid>
            </Grid>
          )}
        <Box
              sx={{
                position: "fixed",
                bottom: 0,
                right: 0,
                padding: "1em",
              }}
            >
              <Link
                href="/privacy-policy"
                underline="none"
                color="textSecondary"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  textDecoration:"underline"
                }}
              >
                privacy policy
              </Link>
            </Box>
          </Grid>
        )}

      {/* After Search */}
      {hasSearched && (
        <Grid container spacing={2} style={{ height: "100%" }}>
          {/* Left Column */}
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Fixed content at top */}
            <div ref={isMobile ? recipeListRef : null}>
              <SearchBar
                query={query}
                setQuery={(newQuery) => updateURL(newQuery, filters, 1)}
                handleSearch={() => updateURL(query, filters, 1)}
              />
              {/* Selected Filters and Filters Button */}
              <Grid container sx={{ marginTop: theme.spacing(1) }}>
                <Container disableGutters sx={{ padding: 0 }}>
                  {selectedFilters.map((filter, index) => (
                    <Chip
                      key={index}
                      label={
                        <span>
                          {filter.type.toLowerCase()}:{" "}
                          <i>
                            {filter.type === "Time"
                              ? `< ${filter.value} minutes`
                              : filter.type === "Owner" &&
                                user &&
                                filter.value === user.id
                              ? user.email
                              : filter.value}
                          </i>
                        </span>
                      }
                      onDelete={() => {
                        if (filter.type === "Time") {
                          updateFilters(filter.type, "", true);
                        } else {
                          updateFilters(filter.type, filter.value, true);
                        }
                      }}
                      sx={{
                        borderRadius: ".5em",
                        margin: theme.spacing(0, 0.5, 0.5, 0),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "12px",
                      }}
                    />
                  ))}
                  <Button
                    size="small"
                    sx={{
                      marginLeft: "auto",
                      display: "block",
                      textTransform: "none",
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    + filter
                  </Button>
                </Container>
              </Grid>
              {/* Filters Panel */}
              {showFilters && (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ width: isMobile ? "100%" : "80%" }}
                  >
                    <DynamicFilter
                      updateFilters={updateFilters}
                      selectedFilters={selectedFilters}
                    />
                  </Grid>
                </Grid>
              )}
              {/* Recipes Found Text */}
              {showResultsText && (
                <div
                  style={{
                    marginTop: theme.spacing(2),
                    marginBottom: "1em",
                    fontFamily: theme.typography.fontFamily,
                    color: "rgb(170, 170, 170)",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "23px",
                    textAlign: isMobile ? "right" : "left",
                    transition: "opacity 0.5s ease",
                  }}
                >
                  {totalCount} recipes found
                </div>
              )}
            </div>
            {/* Scrollable RecipeList */}
            <Box
              ref={!isMobile ? recipeListRef : null}
              sx={{
                flex: 1,
                overflowY: "auto",
                flexGrow: 1,
                minHeight: 0,
                marginBottom: !isMobile ? "" : "2.5em",
              }}
            >
              <RecipeList
                loading={loading}
                errorMessage={errorMessage}
                recipes={showRecipes ? recipes : []}
                hasSearched={hasSearched}
                showResultsText={false}
                totalCount={totalCount}
                onRecipeClick={handleRecipeClick}
                disableExpansion={!isMobile}
                selectedRecipe={!isMobile && selectedRecipe}
              />
            </Box>
            {/* Pagination */}
            {recipes.length > 0 && (
              <div
                style={{
                  ...(isMobile
                    ? {
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        backgroundColor: theme.palette.background.paper,
                        padding: theme.spacing(1, 0),
                        display: "flex",
                        justifyContent: "center",
                        zIndex: 1000,
                        borderTop: `1px solid ${theme.palette.divider}`,
                      }
                    : {
                        borderTop: `1px solid ${theme.palette.divider}`,
                        paddingTop: theme.spacing(1),
                        display: "flex",
                        justifyContent: "center",
                      }),
                }}
              >
                <Pagination
                  count={Math.ceil(totalCount / ITEMS_PER_PAGE)}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    "& .MuiPaginationItem-text": {
                      fontFamily: theme.typography.fontFamily,
                    },
                  }}
                />
              </div>
            )}
          </Grid>

          {/* Right Column */}
          {!isMobile && (
            <Grid
              item
              xs={12}
              md={7}
              lg={8}
              style={{ height: "100%", overflowY: "hidden" }}
            >
              {isNewSearchLoading ? (
                <RecipeExpandedSkeleton />
              ) : selectedRecipe ? (
                <SelectedRecipeDetail recipe={selectedRecipe} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.secondary,
                  }}
                >
                  no preview available
                </div>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default SearchRecipes;