import { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";

function SearchBar({ query, setQuery }) {
  const theme = useTheme();
  const [localQuery, setLocalQuery] = useState(query);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null); // Create a ref for the input element

  return (
    <form
      style={{
        fontFamily: theme.typography.fontFamily,
        display: "flex",
        border: `0.075em solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
      }}
      onSubmit={(e) => {
        e.preventDefault();
        setQuery(localQuery);
        inputRef.current.blur(); // Blur the input to hide the virtual keyboard
      }}
    >
      <input
        type="text"
        value={localQuery}
        onChange={(e) => setLocalQuery(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={inputRef} // Attach the ref to the input element
        style={{
          flex: 1,
          padding: "1em 0 1em 1em",
          border: "none",
          outline: "none",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          fontFamily: theme.typography.fontFamily,
          fontSize: "1rem",
          height: "3.75em",
          lineHeight: "27px",
        }}
        id="searchInput"
      />
      <label
        htmlFor="searchInput"
        style={{
          position: "absolute",
          left: "0.9375em",
          top: isFocused || localQuery ? "-0.750em" : "1em",
          fontSize: isFocused || localQuery ? "0.8125em" : "1rem",
          transition: "top 0.3s ease, font-size 0.3s ease",
          cursor: "text",
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.paper,
          paddingLeft: "0.3125em",
          paddingRight: "0.3125em",
          userSelect: "none",
        }}
      >
        search recipes
      </label>
      <button
        type="submit"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: theme.palette.background.default,
          border: "none",
          cursor: "pointer",
          borderRadius: theme.shape.borderRadius,
          padding: "0 calc(0.6em + 1vw)",
        }}
      >
        <SearchIcon style={{ color: theme.palette.text.primary }} />
      </button>
    </form>
  );
}

export default SearchBar;
