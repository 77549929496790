import { useEffect, useContext, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import RecipeDetail from "./RecipeDetail";
import QuickActions from "./RecipeQuickActions";
import { FavoritesContext } from "../contexts/FavoritesContext";
// import customScrollbarStyles from "./customScrollbarStyles";

function SelectedRecipeDetail({ recipe }) {
  const { favoritedRecipes } = useContext(FavoritesContext);
  const isFavorited = favoritedRecipes.includes(recipe.id);
  const theme = useTheme();
  const scrollableRef = useRef(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0 });
    }
  }, [recipe]);

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: "2em",
          right: ".5em",
          zIndex: 1,
          border: `1px solid ${theme.palette.border.main}`,
          backgroundColor: theme.palette.background.actions,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.mode === 'light'
                ? '0px 2px 4px rgba(0, 0, 0, 0.02)'
                : '0px 2px 4px rgba(0, 0, 0, 0.04)'
        }}
      >
        <QuickActions
          recipe={recipe}
          isFavorited={isFavorited}
          showActions={true}
        />
      </Box>

      <Box
        ref={scrollableRef}
        sx={{
          height: "100%",
          overflowY: "auto",
          // ...customScrollbarStyles(theme), // HIDDEN IN INDEX.CSS
          // "&::-webkit-scrollbar": {
          //   display: "none",
          // },
          // "-ms-overflow-style": "none" /* IE and Edge */,
          // "scrollbar-width": "none" /* Firefox */,
        }}
      >
        <RecipeDetail
          recipe={recipe}
          index={0}
          expanded={true}
          disableExpansion={true}
          onRecipeClick={() => {}}
        />
      </Box>
    </Box>
  );
}

export default SelectedRecipeDetail;
