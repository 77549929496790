import React, { useState, useRef, useEffect } from "react";
import { IconButton, Box, Typography, Divider } from "@mui/material";
import FormatSizeIcon from "@mui/icons-material/FormatSize"; // "aA" icon
import { useTheme } from "@mui/material/styles"; // Import useTheme

function FontSettingsMenu({ fontSize, setFontSize }) {
  const theme = useTheme(); // Access MUI theme

  const [isOpen, setIsOpen] = useState(false); // Toggle for the dropdown
  const dropdownRef = useRef(null); // Ref for the dropdown container

  // Toggle the dropdown open/close
  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the component is unmounted or dropdown is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Handle font size increase
  const increaseFontSize = () => {
    setFontSize((prevSize) => Math.min(prevSize + 10, 200)); // Cap font size at 200%
  };

  // Handle font size decrease
  const decreaseFontSize = () => {
    setFontSize((prevSize) => Math.max(prevSize - 10, 50)); // Minimum font size is 50%
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      ref={dropdownRef}
    >
      {/* aA Button to Toggle Custom Dropdown */}
      <IconButton
        onClick={handleToggle}
        disableRipple
        disableFocusRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Remove circle background on hover
            color: theme.palette.text.primary, // Darken the "aA" icon on hover
          },
          color: theme.palette.text.secondary, // Default color for the "aA" icon
        }}
      >
        <FormatSizeIcon />
      </IconButton>

      {/* Custom Dropdown Panel */}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            right: "0px",
            width: "150px",
            padding: "0 5px",
            zIndex: 10, // Ensure it's on top
            borderRadius: '8px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`, // Use theme divider color
            // boxShadow: theme.shadows[3], // Optional: add shadow for depth
          }}
        >
          {/* Caret for the dropdown */}
          <div
            style={{
              position: "absolute",
              top: "-8px",
              right: "11px",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: `8px solid ${theme.palette.divider}`, // Same color as the border
              zIndex: 11, // Make sure the caret is above everything else
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Box for smaller A */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <IconButton
                onClick={decreaseFontSize}
                size="small"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent", // Remove the circle background
                    color: theme.palette.text.primary, // Darker color on hover
                  },
                }}
                disableRipple
                disableFocusRipple
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "1.2rem",
                    "&:hover": { color: theme.palette.text.primary },
                  }}
                >
                  A
                </Typography>
              </IconButton>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            {/* Box for larger A */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <IconButton
                onClick={increaseFontSize}
                size="small"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent", // Remove the circle background
                    color: theme.palette.text.primary, // Darker color on hover
                  },
                }}
                disableRipple
                disableFocusRipple
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "1.8rem",
                    "&:hover": { color: theme.palette.text.primary },
                  }}
                >
                  A
                </Typography>
              </IconButton>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}

export default FontSettingsMenu;