import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  TextField,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Autorenew, Add as AddIcon } from "@mui/icons-material";
import UserContext from "../contexts/userContext";
import RecipeDetail from "../Recipes/RecipeDetail";
import DynamicFilter from "../Search/DynamicFilter";
import supabase from "../supabaseClient"; // Adjust the import path as necessary
import SelectableRecipe from "./SelectableRecipe"; // Import the memoized component
import io from "socket.io-client";

const SERVER_URL = process.env.REACT_APP_MIGRATION_SERVER_URL;

function MealPlanPage() {
  const { session, user } = useContext(UserContext); // Destructure user
  const [mealPlan, setMealPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState(""); // State for status messages
  const [ingredientsInput, setIngredientsInput] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // State for managing replacement
  const [selectedDay, setSelectedDay] = useState(null);
  const [replacementOptions, setReplacementOptions] = useState([]);
  const [showReplacementDialog, setShowReplacementDialog] = useState(false);
  const [replacementType, setReplacementType] = useState(""); // "mainCourse" or "sideDish"

  // State for showing/hiding filters
  const [showFilters, setShowFilters] = useState(false);

  // State for Create List feature
  const [showCreateListDialog, setShowCreateListDialog] = useState(false);
  const [selectedRecipes, setSelectedRecipes] = useState({});
  const [listName, setListName] = useState("");
  const [creatingList, setCreatingList] = useState(false); // For loading state

  // Socket reference
  const socketRef = useRef(null);

  // Access token for authentication
  const accessToken = session?.access_token;

  useEffect(() => {
    // Initialize socket connection with authentication
    socketRef.current = io(SERVER_URL, {
      auth: {
        token: accessToken,
      },
    });

    socketRef.current.on("connect", () => {
      console.log(`Connected with socket ID: ${socketRef.current.id}`);
    });

    // Handle socket disconnection
    socketRef.current.on("disconnect", (reason) => {
      console.log(`Socket disconnected: ${reason}`);
    });

    // Handle socket connection errors
    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    return () => {
      // Cleanup on unmount
      socketRef.current.disconnect();
    };
  }, [accessToken]);

  const handleFetchMealPlan = async () => {
    if (ingredients.length === 0) {
      setErrorMessage("Please add at least one ingredient.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setStatusMessage(""); // Reset status message
    setMealPlan(null); // Clear previous meal plan

    try {
      // Build headers conditionally
      const headers = { "Content-Type": "application/json" };
      if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
      }

      // Extract dietaryRestrictions and activeTime from selectedFilters
      const dietaryRestrictions = selectedFilters
        .filter((filter) => filter.type === "Tag")
        .map((filter) => filter.value);

      const activeTimeFilter = selectedFilters.find(
        (filter) => filter.type === "Time"
      );
      const activeTime = activeTimeFilter
        ? parseInt(activeTimeFilter.value)
        : null;

      const response = await fetch(`${SERVER_URL}/api/meal-plan`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          ingredients,
          dietaryRestrictions,
          activeTime,
        }),
      });

      const responseData = await response.json();

      if (response.ok && responseData.task_id) {
        const taskId = responseData.task_id;

        // Subscribe to task updates
        socketRef.current.emit("subscribeToTask", { taskId });

        // Listen for task updates
        listenForTaskUpdates(taskId);
      } else {
        setErrorMessage("Failed to start meal plan generation.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching meal plan:", error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  // Function to listen for task updates
  const listenForTaskUpdates = (taskId) => {
    const updateListener = (data) => {
      if (data.taskId === taskId && data.message) {
        console.log(`Task ${taskId} update:`, data.message);
        // Update status message
        setStatusMessage(data.message);
      }
    };

    const completeListener = (data) => {
      if (data.taskId === taskId) {
        console.log(`Task ${taskId} completed`, data);
        setMealPlan(data.result.mealPlan);
        setLoading(false);
        setStatusMessage(""); // Clear status message

        // Remove listeners
        removeTaskListeners();
      }
    };

    const failedListener = (data) => {
      if (data.taskId === taskId) {
        console.error(`Task ${taskId} failed:`, data.error);
        setErrorMessage("Failed to generate meal plan.");
        setLoading(false);
        setStatusMessage(""); // Clear status message

        // Remove listeners
        removeTaskListeners();
      }
    };

    // Attach listeners
    socketRef.current.on(`task-update-${taskId}`, updateListener);
    socketRef.current.on(`task-complete-${taskId}`, completeListener);
    socketRef.current.on(`task-failed-${taskId}`, failedListener);

    // Function to remove listeners
    const removeTaskListeners = () => {
      socketRef.current.off(`task-update-${taskId}`, updateListener);
      socketRef.current.off(`task-complete-${taskId}`, completeListener);
      socketRef.current.off(`task-failed-${taskId}`, failedListener);
    };
  };

  const handleAddIngredient = () => {
    const trimmedInput = ingredientsInput.trim().toLowerCase();
    if (trimmedInput && !ingredients.includes(trimmedInput)) {
      setIngredients([...ingredients, trimmedInput]);
      setIngredientsInput("");
    }
  };

  const handleDeleteIngredient = (ingredientToDelete) => {
    setIngredients(ingredients.filter((ing) => ing !== ingredientToDelete));
  };

  // Handle filter updates from DynamicFilter component
  const updateFilters = (type, value) => {
    if (value) {
      setSelectedFilters((prevFilters) => {
        if (type === "Time") {
          // Remove any existing Time filters
          const filtersWithoutTime = prevFilters.filter(
            (filter) => filter.type !== "Time"
          );
          // Add the new Time filter
          return [...filtersWithoutTime, { type, value }];
        } else {
          return [...prevFilters, { type, value }];
        }
      });
    }
  };

  const removeFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter(
        (filter) =>
          !(
            filter.type === filterToRemove.type &&
            filter.value === filterToRemove.value
          )
      )
    );
  };

  // Handle replace action
  const handleReplaceClick = (day, type) => {
    const dayPlan = mealPlan[day];
    const options =
      type === "mainCourse"
        ? dayPlan.mainCourse.alternatives
        : dayPlan.sideDish.alternatives;

    if (options && options.length > 0) {
      setSelectedDay(day);
      setReplacementOptions(options);
      setReplacementType(type);
      setShowReplacementDialog(true);
    } else {
      // No alternatives available
      alert(
        `No alternative ${
          type === "mainCourse" ? "main courses" : "side dishes"
        } available for this day.`
      );
    }
  };

  const handleReplacementSelect = (recipe) => {
    setMealPlan((prevMealPlan) => ({
      ...prevMealPlan,
      [selectedDay]: {
        ...prevMealPlan[selectedDay],
        [replacementType]: {
          ...prevMealPlan[selectedDay][replacementType],
          recipe: recipe,
        },
      },
    }));
    setShowReplacementDialog(false);
  };

  const handleCloseReplacementDialog = () => {
    setShowReplacementDialog(false);
  };

  // Handle recipe selection changes
  const handleRecipeSelectionChange = useCallback((key, isSelected) => {
    setSelectedRecipes((prev) => ({
      ...prev,
      [key]: isSelected,
    }));
  }, []);

  // Handle opening the Create List dialog
  const handleOpenCreateListDialog = () => {
    if (!mealPlan) {
      alert("Please generate a meal plan first.");
      return;
    }
    // Initialize selectedRecipes with all recipes selected by default
    const initialSelection = {};
    Object.entries(mealPlan).forEach(([day, { mainCourse, sideDish }]) => {
      if (mainCourse && mainCourse.recipe) {
        initialSelection[`${day}-mainCourse`] = true;
      }
      if (sideDish && sideDish.recipe) {
        initialSelection[`${day}-sideDish`] = true;
      }
    });
    setSelectedRecipes(initialSelection);
    setShowCreateListDialog(true);
  };

  // Handle list creation with Supabase
  const handleCreateList = async () => {
    if (!user) {
      alert("You must be logged in to create a list.");
      return;
    }

    if (!listName.trim()) {
      alert("Please enter a list name.");
      return;
    }

    const selectedRecipeIds = [];

    Object.entries(selectedRecipes).forEach(([key, isSelected]) => {
      if (isSelected) {
        const [day, type] = key.split("-");
        const recipe =
          type === "mainCourse"
            ? mealPlan[day].mainCourse.recipe
            : mealPlan[day].sideDish.recipe;
        if (recipe && recipe.id) {
          selectedRecipeIds.push(recipe.id);
        }
      }
    });

    if (selectedRecipeIds.length === 0) {
      alert("Please select at least one recipe.");
      return;
    }

    try {
      setCreatingList(true);

      // Insert new list into saved_lists
      const { data, error } = await supabase
        .from("saved_lists")
        .insert([{ list_name: listName.trim(), user_id: user.id }])
        .select()
        .single();

      if (error) {
        console.error("Error creating new list:", error);
        alert("An error occurred while creating the list.");
        setCreatingList(false);
        return;
      }

      const listId = data.list_id;

      // Insert recipes into lists_recipes
      const insertData = selectedRecipeIds.map((recipeId) => ({
        list_id: listId,
        recipe_id: recipeId,
      }));

      const { error: recipesError } = await supabase
        .from("lists_recipes")
        .insert(insertData);

      if (recipesError) {
        console.error("Error adding recipes to list:", recipesError);
        alert("An error occurred while adding recipes to the list.");
        setCreatingList(false);
        return;
      }

      // List creation successful
      setShowCreateListDialog(false);
      setListName("");
      setSelectedRecipes({});
      alert("List created successfully!");
    } catch (error) {
      console.error("Error during list creation:", error);
      alert("An unexpected error occurred.");
    } finally {
      setCreatingList(false);
    }
  };

  return (
    <Box
      sx={{
        padding: isMobile ? theme.spacing(2, 0) : theme.spacing(4, 0),
        maxWidth: "800px",
        margin: "0 auto",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: theme.spacing(2),
          fontFamily: theme.typography.fontFamily,
          textAlign: "left",
        }}
      >
        meal plan
      </Typography>

      {/* Filters Button and DynamicFilter Component */}
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Button
          size="small"
          sx={{
            marginLeft: "auto",
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          + filter
        </Button>
      </Box>
      {showFilters && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              width: isMobile ? "100%" : "80%",
              margin: "0 auto",
            }}
          >
            <DynamicFilter
              updateFilters={updateFilters}
              selectedFilters={selectedFilters}
              filterOptions={["Tag", "Time"]} // Only include "Tag" and "Time" filters
            />
          </Grid>
        </Grid>
      )}

      {/* Ingredients Input */}
      <Box sx={{ marginTop: "" }}>
        <TextField
          value={ingredientsInput}
          onChange={(e) => setIngredientsInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddIngredient();
            }
          }}
          placeholder="add ingredient"
          variant="standard"
          fullWidth
          sx={{
            fontFamily: theme.typography.fontFamily,
            padding: "0 0.5em 0 .5em",
            marginBottom: theme.spacing(2),
            "& .MuiInputBase-input": {
              padding: 0,
              fontSize: "16px",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleAddIngredient}
                edge="end"
                disabled={!ingredientsInput.trim()}
              >
                <AddIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      {/* Display Added Ingredients and Selected Filters */}
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        {ingredients.map((ingredient, index) => (
          <Chip
            key={`ingredient-${index}`}
            label={ingredient}
            onDelete={() => handleDeleteIngredient(ingredient)}
            sx={{
              marginRight: theme.spacing(0.5),
              marginBottom: theme.spacing(0.5),
              fontFamily: theme.typography.fontFamily,
            }}
          />
        ))}
        {/* Display Selected Filters */}
        {selectedFilters.map((filter, index) => (
          <Chip
            key={`filter-${index}`}
            label={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ fontStyle: "italic", textTransform: "lowercase" }}
                >
                  {filter.type}
                </Typography>
                {`: ${filter.value.toUpperCase()}`}
              </React.Fragment>
            }
            onDelete={() => removeFilter(filter)}
            sx={{
              marginRight: theme.spacing(0.5),
              marginBottom: theme.spacing(0.5),
              fontFamily: theme.typography.fontFamily,
            }}
          />
        ))}
      </Box>

      {/* Generate Meal Plan Button */}
      <Box sx={{ textAlign: "center", marginBottom: theme.spacing(2) }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchMealPlan}
          disabled={loading || ingredients.length === 0}
          sx={{
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {loading ? "generating..." : "generate meal plan"}
        </Button>
      </Box>

      {errorMessage && (
        <Typography
          color="error"
          sx={{ textAlign: "center", marginBottom: theme.spacing(2) }}
        >
          {errorMessage}
        </Typography>
      )}

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ textAlign: "center", marginTop: theme.spacing(2) }}>
          <CircularProgress />
          {/* Display status message under the spinner */}
          {statusMessage && (
            <Typography
              variant="body2"
              sx={{
                marginTop: theme.spacing(1),
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {statusMessage}
            </Typography>
          )}
        </Box>
      )}

      {/* Meal Plan Display */}
      {mealPlan && !loading && (
        <Box sx={{ marginTop: theme.spacing(4) }}>
          <Grid container spacing={2}>
            {Object.entries(mealPlan).map(([day, { mainCourse, sideDish }]) => (
              <Grid item xs={12} key={day}>
                <Paper
                  sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.border.main}`,
                  }}
                  elevation={0}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: theme.spacing(2),
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    {day.toLowerCase()}
                  </Typography>

                  {mainCourse && mainCourse.recipe ? (
                    <Box sx={{ marginBottom: theme.spacing(2) }}>
                      {/* Header with Replace Icon */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: "bold",
                          }}
                        >
                          main course
                        </Typography>
                        <IconButton
                          onClick={() => handleReplaceClick(day, "mainCourse")}
                          aria-label="replace main course"
                          size="small"
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          <Autorenew fontSize="small" />
                        </IconButton>
                      </Box>
                      <RecipeDetail
                        recipe={mainCourse.recipe}
                        index={0}
                        disableExpansion={false}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      main course not available.
                    </Typography>
                  )}

                  {sideDish && sideDish.recipe ? (
                    <Box sx={{ marginBottom: theme.spacing(2) }}>
                      {/* Header with Replace Icon */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: "bold",
                          }}
                        >
                          side dish
                        </Typography>
                        <IconButton
                          onClick={() => handleReplaceClick(day, "sideDish")}
                          aria-label="replace side dish"
                          size="small"
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          <Autorenew fontSize="small" />
                        </IconButton>
                      </Box>
                      <RecipeDetail
                        recipe={sideDish.recipe}
                        index={0}
                        disableExpansion={false}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      side dish not available.
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* Create List Button */}
          <Box sx={{ textAlign: "center", marginTop: theme.spacing(4) }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenCreateListDialog}
              sx={{
                textTransform: "none",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              create list
            </Button>
          </Box>
        </Box>
      )}

      {/* Replacement Dialog */}
      <Dialog
        open={showReplacementDialog}
        onClose={handleCloseReplacementDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.modal,
            border: `1px solid ${theme.palette.border.main}`,
          },
          elevation: 2,
        }}
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          replace {" "}
          {replacementType === "mainCourse" ? "main course" : "side dish"}
        </DialogTitle>
        <DialogContent dividers>
          {replacementOptions.map((recipe, idx) => (
            <Box
              key={recipe.id}
              sx={{
                // marginBottom: theme.spacing(2),
              }}
            >
              <RecipeDetail
                recipe={recipe}
                index={idx}
                disableExpansion={false}
                compact={true}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleReplacementSelect(recipe)}
                sx={{
                  // mt: 1,
                  textTransform: "none",
                  fontFamily: theme.typography.fontFamily,
                  marginBottom: theme.spacing(3),

                }}
              >
                select recipe
              </Button>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseReplacementDialog}
            sx={{
              textTransform: "none",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create List Dialog */}
      {mealPlan && (
        <Dialog
          open={showCreateListDialog}
          onClose={() => setShowCreateListDialog(false)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.modal,
              border: `1px solid ${theme.palette.border.main}`,
            },
            elevation: 2,
          }}
        >
          <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
            create list
          </DialogTitle>
          <DialogContent dividers>
            {/* List Name Input */}
            <TextField
              label="list name"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              fullWidth
              variant="standard"
              sx={{ marginBottom: theme.spacing(4) }}
            />

            {/* Selection of Recipes */}
            {Object.entries(mealPlan).map(([day, { mainCourse, sideDish }]) => (
              <Box
                key={day}
                sx={{
                  marginBottom: theme.spacing(2),
                  border: `1px solid ${theme.palette.border.main}`,
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(2),
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {day.toLowerCase()}
                </Typography>

                {/* Main Course */}
                {mainCourse && mainCourse.recipe && (
                  <SelectableRecipe
                    recipeKey={`${day}-mainCourse`}
                    recipeTitle="main course"
                    recipe={mainCourse.recipe}
                    selected={selectedRecipes[`${day}-mainCourse`] || false}
                    onChange={handleRecipeSelectionChange}
                  />
                )}

                {/* Side Dish */}
                {sideDish && sideDish.recipe && (
                  <SelectableRecipe
                    recipeKey={`${day}-sideDish`}
                    recipeTitle="side dish"
                    recipe={sideDish.recipe}
                    selected={selectedRecipes[`${day}-sideDish`] || false}
                    onChange={handleRecipeSelectionChange}
                  />
                )}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowCreateListDialog(false)}
              sx={{
                textTransform: "none",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateList}
              disabled={!listName.trim() || creatingList}
              sx={{
                textTransform: "none",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {creatingList ? "creating..." : "create list"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default MealPlanPage;
