import { ReactReader } from 'react-reader';

// Extend the ReactReader component to handle TOC toggling and trigger a callback
class ExtendedReactReader extends ReactReader {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      expandedToc: false, // Initialize the state for the TOC expansion
    };
  }

  // Override the toggleToc function to handle TOC expansion and notify the parent component
  toggleToc = () => {
    const { onTocToggle } = this.props;
    this.setState(
      (prevState) => ({
        expandedToc: !prevState.expandedToc, // Toggle the TOC state
      }),
      () => {
        // Call the onTocToggle prop function, passing the new state of the TOC
        if (onTocToggle) {
          onTocToggle(this.state.expandedToc);
        }
      }
    );
  };

  // Override the setLocation method to close the TOC when a TOC item is clicked
  setLocation = (loc) => {
    const { locationChanged } = this.props;
    
    // Call the original logic to change the location
    this.setState(
      {
        expandedToc: false, // Close the TOC when navigating to a new section
      },
      () => {
        if (locationChanged) {
          locationChanged(loc); // Notify the parent component of the location change
        }
        const { onTocToggle } = this.props;
        if (onTocToggle) {
          onTocToggle(false); // Notify that the TOC is closed
        }
      }
    );
  };

  render() {
    // Call the parent render function to retain original behavior
    return super.render();
  }
}

export default ExtendedReactReader;
