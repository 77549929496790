// ListsPage.jsx

import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import FavoriteRecipes from './FavoriteRecipes';
import supabase from '../supabaseClient';
import UserContext from '../contexts/userContext';
import ListOverview from './ListOverview';

function ListsPage() {
  const [ownedLists, setOwnedLists] = useState([]);
  const [sharedLists, setSharedLists] = useState([]);
  const { user } = useContext(UserContext);

  // Sorting function
  const sortLists = (lists) => {
    return lists.sort((a, b) => {
      if (a.list_name.toLowerCase() === 'favorites') return -1;
      if (b.list_name.toLowerCase() === 'favorites') return 1;

      if (a.pinned !== b.pinned) {
        return b.pinned - a.pinned;
      }

      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  useEffect(() => {
    const fetchLists = async () => {
      const { data, error } = await supabase
        .from('saved_lists')
        .select(`
          *,
          profiles:user_id (
            first_name,
            last_name,
            avatar_url
          )
        `);

      if (error) {
        console.error('Error fetching lists:', error);
      } else {
        const sortedLists = sortLists(data || []);
        // Separate lists into owned and shared
        const owned = sortedLists.filter((list) => list.user_id === user.id);
        const shared = sortedLists.filter((list) => list.user_id !== user.id);
        setOwnedLists(owned);
        setSharedLists(shared);
      }
    };

    if (user) {
      fetchLists();
    }
  }, [user]);

  const handleTogglePin = async (listId, currentPinnedStatus) => {
    const { error } = await supabase
      .from('saved_lists')
      .update({ pinned: !currentPinnedStatus })
      .eq('list_id', listId)
      .eq('user_id', user.id); // Ensure only the owner can pin/unpin

    if (error) {
      console.error('Error updating pinned status:', error);
    } else {
      setOwnedLists((prevLists) => {
        const updatedLists = prevLists.map((list) =>
          list.list_id === listId
            ? { ...list, pinned: !currentPinnedStatus }
            : list
        );
        return sortLists(updatedLists);
      });
    }
  };

  const addNewList = (newList) => {
    setOwnedLists((prev) => {
      const updatedLists = [...prev, newList];
      return sortLists(updatedLists);
    });
  };

  // Function to update an existing list
  const updateList = (updatedList) => {
    setOwnedLists((prevLists) => {
      const index = prevLists.findIndex((list) => list.list_id === updatedList.list_id);
      if (index !== -1) {
        const newLists = [...prevLists];
        newLists[index] = updatedList;
        return sortLists(newLists);
      }
      return prevLists;
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ListOverview
            ownedLists={ownedLists}
            sharedLists={sharedLists}
            onNewListCreated={addNewList}
            onTogglePin={handleTogglePin}
            onUpdateList={updateList}
          />
        }
      />
      {[...ownedLists, ...sharedLists].map((list) => (
        <Route
          key={list.list_id}
          path={`/${list.list_id}`}
          element={
            <FavoriteRecipes listId={list.list_id} listName={list.list_name} />
          }
        />
      ))}
    </Routes>
  );
}

export default ListsPage;
