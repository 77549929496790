import React, { useContext, useState, useEffect } from "react";
import { Skeleton, Alert, useTheme, useMediaQuery } from "@mui/material";
import RecipeDetail from "./RecipeDetail";
import RecipeLoadingSkeleton from "./Skeletons/RecipeLoadingSkeleton";
import { FavoritesContext } from "../contexts/FavoritesContext";

function RecipeList({
  loading,
  errorMessage,
  recipes,
  hasSearched,
  showResultsText,
  totalCount,
  onRecipeClick,
  disableExpansion,
  selectedRecipe,
}) {
  const theme = useTheme();
  const { favoritedRecipes } = useContext(FavoritesContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // State variables for the hint animation
  const [isHinting, setIsHinting] = useState(false);
  const [hasHinted, setHasHinted] = useState(() => {
    return localStorage.getItem("hasHinted") === "true";
  });

  useEffect(() => {
    if (!hasHinted && recipes.length > 0) {
      const baseDelay = 200; // Should match RecipeDetail
      const staggerDelay = 150; // Should match RecipeDetail
      const hintStartDelay = baseDelay + staggerDelay * 0 + 1000; // Start hint 500ms after first item is visible

      // Start the hint animation after the first item is visible
      const hintTimeout = setTimeout(() => {
        setIsHinting(true);
      }, hintStartDelay);

      return () => {
        clearTimeout(hintTimeout);
      };
    }
  }, [hasHinted, recipes.length]);

  if (errorMessage) return <Alert severity="error">{errorMessage}</Alert>;

  if (loading) {
    return (
      <div>
        {/* Loading Skeleton */}
        <div
          style={{
            marginBottom: "1em",
            fontFamily: theme.typography.fontFamily,
            color: "rgb(170, 170, 170)",
            fontSize: ".8rem",
            marginLeft: "auto",
            textAlign: isMobile ? "right" : "left",
            transition: "opacity 0.5s ease",
          }}
        >
          <Skeleton
            variant="text"
            width={100}
            style={{ display: "inline-block" }}
          />
        </div>

        {/* Skeletons for Recipe Frames */}
        {Array.from({ length: 10 }).map((_, index) => (
          <RecipeLoadingSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (hasSearched && recipes.length === 0) {
    return (
      <Alert severity="error" style={{ marginTop: "1em" }}>
        No recipes found. Please try different filters or search terms.
      </Alert>
    );
  }

  if (!loading && recipes.length > 0) {
    return (
      <>
        {showResultsText && (
          <div
            style={{
              marginBottom: "1em",
              fontFamily: theme.typography.fontFamily,
              color: "rgb(170, 170, 170)",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "23px",
              textAlign: "right",
              transition: "opacity 0.5s ease",
            }}
          >
            {totalCount} recipes found
          </div>
        )}
        {recipes.map((recipe, index) => (
          <RecipeDetail
            key={recipe.id}
            recipe={recipe}
            index={index}
            isFavorited={favoritedRecipes.includes(recipe.id)}
            onRecipeClick={onRecipeClick}
            disableExpansion={disableExpansion}
            isSelected={selectedRecipe && recipe.id === selectedRecipe.id}
            isHinting={isMobile && isHinting && index === 0} // Show hint only on the first item on mobile
            onHintComplete={() => {
              setIsHinting(false);
              setHasHinted(true);
              localStorage.setItem("hasHinted", "true");
            }}
          />
        ))}
      </>
    );
  }

  return null; // Ensure the component returns something in all code paths
}

export default RecipeList;